<template>
    <PageCad namePage="cad-transportadoras-api" title="Configurações da transportadora" :subtitle="subTitleTra" empAdmin="S"
        :colunas="colunas" :listRegistros="listTransportadorasApi" :loadRegistro="loadRegistro" :loadRegistros="loadRegistros"
        :mode="mode" :save="save" :remove="remove" :reset="reset"
        labelCadSecundario="" :fncCadSecundario="null">
        
        <template v-slot:formulario>
            <v-form>
                <v-text-field v-model="traApi.tap_campo" label="Campo" :color="consts.corEscura" dense />
                <v-select v-model="traApi.stt_id" label="Status" :items="listStt" :color="consts.corEscura" dense />
                <v-checkbox v-model="traApi.tap_ignorar_final" label="Ignorar final?" true-value="S" false-value="N" :color="consts.corEscura" dense />
            </v-form>
        </template>

    </PageCad>
</template>

<script>
import PageCad from '@/components/templates/PageCad'
import consts from '@/global/consts'
import functions from '@/global/functions'
import axios from 'axios'

export default {
    name: 'CadTransportadorasApi',
    props: [
        'traId',
        'traNome'
    ],
    components: {
        PageCad
    },
    data() {
        return {
            consts,
            subTitleTra: '',
            traApi: {},
            listTransportadorasApi: [],
            listStt: [],
            mode: 'save',
            colunas: [
                {
                    text: 'Código',
                    value: 'tap_id'
                },
                {
                    text: 'Campo',
                    value: 'tap_campo'
                },
                {
                    text: 'Status de transporte',
                    value: 'stt_descricao'
                },
                {
                    text: 'Ignorar final?',
                    value: 'tap_ignorar_final'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        loadRegistro(registro, mode = 'save') {
            this.mode = mode
            this.traApi = { ...registro }
        },
        loadRegistros() {
            axios.get(`${consts.urlBaseApi}/transportadoras/${this.traId}/api`)
                .then(r => {
                    this.listTransportadorasApi = r.data.data
                })
                .catch(functions.showError)
        },
        remove() {
            axios.delete(`${consts.urlBaseApi}/transportadoras/${this.traId}/api/${this.traApi.tap_id}`)
                .then(() => {
                    functions.showSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        },
        reset() {
            this.mode = 'save'
            this.traApi = {}
            
            this.loadRegistros()
        },
        save() {
            const method = this.traApi.tap_id ? 'put' : 'post'
            const id = this.traApi.tap_id ? `/${this.traApi.tap_id}` : ''

            const pBody = {
                tra_id: this.traId,
                tap_campo: this.traApi.tap_campo,
                stt_id: this.traApi.stt_id,
                tap_ignorar_final: this.traApi.tap_ignorar_final ? this.traApi.tap_ignorar_final : 'N'
            }
            
            axios[method](`${consts.urlBaseApi}/transportadoras/${this.traId}/api${id}`, pBody)
                .then(() => {
                    functions.showSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        }
    },
    async mounted() {
        if (this.traId > 0) {
            this.subTitleTra = `Configurações da API da transportadora ${this.traNome}`
            this.listStt = await functions.getListStatusTransporte()
        }
        else {
            this.$router.push({
                name: 'homeUsuario'
            })
        }
    }
}
</script>

<style>

</style>