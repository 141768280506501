<template>
    <PageCad namePage="cad-transportadoras" title="Cadastro de transportadoras" subtitle="Cadastro de transportadoras da empresa" empAdmin="S"
        :colunas="colunas" :listRegistros="listTransportadoras" :loadRegistro="loadRegistro" :loadRegistros="loadRegistros"
        :mode="mode" :save="save" :remove="remove" :reset="reset"
        labelCadSecundario="Configuração" :fncCadSecundario="openCadTransportadorasApi">
        
        <template v-slot:formulario>
            <v-form>
                <v-text-field v-model="transportadora.tra_nome" label="Nome" :color="consts.corEscura" dense />
                <v-text-field v-model="transportadora.tra_cnpj" label="CNPJ" v-mask="'##.###.###/####-##'" :color="consts.corEscura" dense />
                <v-text-field v-model="transportadora.tra_usuario_sinc" label="Usuário (API da transportadora)" :color="consts.corEscura" dense />
                <v-text-field v-model="transportadora.tra_senha_sinc" label="Senha (API da transportadora)" type="password" :color="consts.corEscura" dense />
                <v-text-field v-model="transportadora.tra_token_sinc" label="Token (API da transportadora)" :color="consts.corEscura" dense />
                <v-checkbox v-model="transportadora.tra_sincronizada" label="Sincronizada?" true-value="S" false-value="N" :color="consts.corEscura" dense />
                <v-checkbox v-model="transportadora.tra_ativa" label="Ativa?" true-value="S" false-value="N" :color="consts.corEscura" dense />
            </v-form>
        </template>

    </PageCad>
</template>

<script>
import PageCad from '@/components/templates/PageCad'
import consts from '@/global/consts'
import functions from '@/global/functions'
import axios from 'axios'

export default {
    name: 'CadTransportadoras',
    components: {
        PageCad
    },
    data() {
        return {
            consts,
            transportadora: {},
            listTransportadoras: [],
            mode: 'save',
            colunas: [
                {
                    text: 'Código',
                    value: 'tra_id'
                },
                {
                    text: 'Nome',
                    value: 'tra_nome'
                },
                {
                    text: 'CNPJ',
                    value: 'tra_cnpj'
                },
                {
                    text: 'Sincronizada?',
                    value: 'tra_sincronizada'
                },
                {
                    text: 'Ativa?',
                    value: 'tra_ativa'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        loadRegistro(registro, mode = 'save') {
            this.mode = mode
            this.transportadora = { ...registro }
        },
        loadRegistros() {
            axios.get(`${consts.urlBaseApi}/transportadoras`)
                .then(r => {
                    this.listTransportadoras = r.data.data
                })
                .catch(functions.showError)
        },
        openCadTransportadorasApi(pTransp) {
            this.$router.push({
                name: 'transportadorasApi',
                params: {
                    traId: pTransp.tra_id,
                    traNome: pTransp.tra_nome
                }
            })
            
        },
        remove() {
            axios.delete(`${consts.urlBaseApi}/transportadoras/${this.transportadora.tra_id}`)
                .then(() => {
                    functions.showSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        },
        reset() {
            this.mode = 'save'
            this.transportadora = {}
            
            this.loadRegistros()
        },
        save() {
            const method = this.transportadora.tra_id ? 'put' : 'post'
            const id = this.transportadora.tra_id ? `/${this.transportadora.tra_id}` : ''

            const pBody = {
                emp_id: this.$store.state.usuario.emp_id,
                tra_nome: this.transportadora.tra_nome,
                tra_cnpj: functions.getOnlyNumbers(this.transportadora.tra_cnpj),
                tra_usuario_sinc: this.transportadora.tra_usuario_sinc,
                tra_senha_sinc: this.transportadora.tra_senha_sinc,
                tra_token_sinc: this.transportadora.tra_token_sinc,
                tra_sincronizada: this.transportadora.tra_sincronizada ? this.transportadora.tra_sincronizada : 'N',
                tra_ativa: this.transportadora.tra_ativa ? this.transportadora.tra_ativa : 'N'
            }

            axios[method](`${consts.urlBaseApi}/transportadoras${id}`, pBody)
                .then(() => {
                    functions.showSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        }
    }
}
</script>

<style>

</style>