<template>
    <PageCad namePage="cad-lojas" title="Cadastro de lojas" subtitle="Cadastro de lojas da empresa" empAdmin="S"
        :colunas="colunas" :listRegistros="listLojas" :loadRegistro="loadRegistro" :loadRegistros="loadRegistros"
        :mode="mode" :save="save" :remove="remove" :reset="reset"
        labelCadSecundario="" :fncCadSecundario="null">

        <template v-slot:formulario>
            <v-form>
                <v-text-field v-model="loja.loj_nome" label="Nome" :color="consts.corEscura" dense />
                <v-text-field v-model="loja.loj_cnpj" label="CNPJ" v-mask="'##.###.###/####-##'" :color="consts.corEscura" dense />
                <v-text-field v-model="loja.loj_rua" label="Rua" :color="consts.corEscura" dense />
                <v-text-field v-model="loja.loj_numero" label="Número" :color="consts.corEscura" dense />
                <v-text-field v-model="loja.loj_bairro" label="Bairro" :color="consts.corEscura" dense />
                <v-text-field v-model="loja.loj_complemento" label="Complemento" :color="consts.corEscura" dense />
                <v-text-field v-model="loja.loj_cidade" label="Cidade" :color="consts.corEscura" dense />
                <v-select v-model="loja.loj_uf" label="UF" :items="fieldContents.listUFs" :color="consts.corEscura" dense />
                <v-checkbox v-model="loja.loj_ativa" label="Ativa?" true-value="S" false-value="N" :color="consts.corEscura" dense />
            </v-form>
        </template>

    </PageCad>
</template>

<script>
import PageCad from '@/components/templates/PageCad'
import consts from '@/global/consts'
import functions from '@/global/functions'
import fieldContents from '@/global/fieldContents'
import axios from 'axios'

export default {
    name: 'CadLojas',
    components: {
        PageCad
    },
    data() {
        return {
            consts,
            fieldContents,
            loja: {},
            listLojas: [],
            mode: 'save',
            colunas: [
                {
                    text: 'Código',
                    value: 'loj_id'
                },
                {
                    text: 'Nome',
                    value: 'loj_nome'
                },
                {
                    text: 'CNPJ',
                    value: 'loj_cnpj'
                },
                {
                    text: 'Rua',
                    value: 'loj_rua'
                },
                {
                    text: 'Número',
                    value: 'loj_numero'
                },
                {
                    text: 'Bairro',
                    value: 'loj_bairro'
                },
                {
                    text: 'Cidade',
                    value: 'loj_cidade'
                },
                {
                    text: 'UF',
                    value: 'loj_uf'
                },
                {
                    text: 'Ativa?',
                    value: 'loj_ativa'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        loadRegistro(registro, mode = 'save') {
            this.mode = mode
            this.loja = { ...registro }
        },
        loadRegistros() {
            axios.get(`${consts.urlBaseApi}/lojas`)
                .then(r => {
                    this.listLojas = r.data.data
                })
                .catch(functions.showError)
        },
        remove() {
            axios.delete(`${consts.urlBaseApi}/lojas/${this.loja.loj_id}`)
                .then(() => {
                    functions.showSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        },
        reset() {
            this.mode = 'save'
            this.loja = {}
            
            this.loadRegistros()
        },
        save() {
            const method = this.loja.loj_id ? 'put' : 'post'
            const id = this.loja.loj_id ? `/${this.loja.loj_id}` : ''

            const pBody = {
                emp_id: this.$store.state.usuario.emp_id,
                loj_nome: this.loja.loj_nome,
                loj_cnpj: functions.getOnlyNumbers(this.loja.loj_cnpj),
                loj_rua: this.loja.loj_rua,
                loj_numero: this.loja.loj_numero,
                loj_bairro: this.loja.loj_bairro,
                loj_complemento: this.loja.loj_complemento,
                loj_cidade: this.loja.loj_cidade,
                loj_uf: this.loja.loj_uf,
                loj_ativa: this.loja.loj_ativa ? this.loja.loj_ativa : 'N'
            }

            axios[method](`${consts.urlBaseApi}/lojas${id}`, pBody)
                .then(() => {
                    functions.showSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        }
    }
}
</script>

<style>

</style>