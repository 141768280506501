<template>
    <PageApp :namePage="namePage" :title="title" :subtitle="subtitle">
        <slot></slot>
    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'

//Template criado sendo exatamente igual à "PageApp", porém com um "create" que é instanciado apenas se o usuário estiver logado no navegador (store)

export default {
    name: 'PageAppUser',
    props: [
        'namePage',
        'title',
        'subtitle',
        'empAdmin'
    ],
    components: {
        PageApp
    },
    mounted() {
        if (this.$store.state.usrInformado) {
            if (this.empAdmin == 'S' && this.$store.state.usuario.emp_tipo != 'A') {
                this.$router.push({
                    name: 'noPermission'
                })
            }
        }
        else {
            this.$router.push({
                name: 'homePage'
            })
        }
    }
}
</script>

<style>

</style>