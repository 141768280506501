<template>
    <PageAppUser namePage="pedidos-abertos" title="Pedidos em aberto" subtitle="Consulta de pedidos em aberto da empresa" empAdmin="N">

        <v-col>
            <v-row v-if="this.$store.state.usuario.emp_tipo == 'A'" class="justify-end mb-3">
                <v-progress-circular v-if="processando" class="ml-5" indeterminate :color="consts.corNeutraEscura" />
                <v-btn v-else @click="atualizarRastreamento" :color="consts.corNeutraEscura" outlined class="mr-2">Atualizar rastreamento</v-btn>
                <v-btn @click="modalFiltros=true" :color="consts.corEscura" outlined>Filtros</v-btn>
            </v-row>

            <v-row>
                <!-- Layout usado pra deixar a tabela com o widht total da página, sem essa tag ficam bordas dos lados. -->
                <v-layout child-flex>
                    <TabelaPedidos :pedidos="pedidos" />
                </v-layout>
            </v-row>
        </v-col>

        <v-dialog v-model="modalFiltros" max-width="600">
            <v-card>
                <v-card-title>Filtros</v-card-title>
                    <v-form>
                        <v-text-field v-model="emissaoInicial" label="Data de emissão inicial:" v-mask="'##/##/####'" dense class="mb-3 mx-3 mt-3"/>
                        <v-text-field v-model="emissaoFinal" label="Data de emissão final:" v-mask="'##/##/####'" dense class="mb-3 mx-3"/>
                        <v-text-field v-model="numeroNF" label="Número da nota fiscal" type="number" dense class="mb-3 mx-3"/>
                        <v-select v-model="traID" :items="listTra" label="Transportadora" :color="consts.corEscura" dense class="mb-3 mx-3"/>
                        <v-btn @click="filtrarPedidos" class="ml-2 mb-2" :color="consts.corNeutraEscura">Filtrar</v-btn>
                        <v-btn @click="modalFiltros=false" class="ml-2 mb-2" :color="consts.corClara" dark>Cancelar</v-btn>
                        <v-btn @click="limparFiltros" class="ml-2 mb-2" :color="consts.corNeutraClara" dark>Limpar</v-btn>
                    </v-form>
            </v-card>
        </v-dialog>
    </PageAppUser>
</template>

<script>
import PageAppUser from '@/components/templates/PageAppUser'
import TabelaPedidos from '@/components/templates/TabelaPedidos'
import consts from '@/global/consts'
import functions from '@/global/functions'
import axios from 'axios'

export default {
    name: 'PedidosAbertos',
    components: {
        PageAppUser,
        TabelaPedidos
    },
    data() {
        return {
            consts,
            emissaoInicial: '',
            emissaoFinal: '',
            numeroNF: '', 
            traID: '', 
            modalFiltros: false,
            processando: false,
            pedidos: [],
            pedidosOriginal: []
        }
    },
    methods: {
        atualizarPedidos() {
            axios.get(`${consts.urlBaseApi}/pedidos_aberto`)
                .then(r => {
                    this.pedidos = r.data.data
                    this.pedidosOriginal = r.data.data
                })
                .catch(functions.showError)
        },
        atualizarRastreamento() {
            this.processando = true

            axios.put(`${consts.urlBaseApi}/rastreamento_pedidos_abertos`)
                .then(() => {
                    functions.showSuccess('Pedidos rastreados com sucesso, verifique!')
                    this.processando = false

                    this.atualizarPedidos()
                })
                .catch(e => {
                    functions.showError(e)
                    this.processando = false
                })
        },
        filtrarPedidos() {
            
            const dataIni = this.emissaoInicial
                ? new Date(functions.formatarData(this.emissaoInicial)).getTime() 
                : new Date('1900-01-01').getTime()
            
            const dataFim = this.emissaoFinal
                ? new Date(functions.formatarData(this.emissaoFinal) + 'T23:59:59.999Z').getTime() 
                : new Date('2999-01-01').getTime()

            if (dataIni > dataFim) {
                return functions.showError('Data inicial maior que a final.')
            }

            this.pedidos = this.pedidosOriginal.filter(pedido => {
                const dataPed = new Date(pedido.ped_emissao).getTime()
                const matchData = dataPed >= dataIni && dataPed <= dataFim
                const matchNF = this.numeroNF ? pedido.ped_numero_nf == this.numeroNF : true
                const matchTra = this.traID ? pedido.tra_id == this.traID : true

                return matchData && matchNF && matchTra
            })

            this.modalFiltros = false
        }, 
        limparFiltros() {
            this.emissaoInicial = ''
            this.emissaoFinal = ''
            this.numeroNF = ''
            this.traID = ''

            this.pedidos = this.pedidosOriginal
            this.modalFiltros = false
        }
    },
    async mounted() {
        if (this.$store.state.usrInformado) {
            this.listTra = await functions.getListTransportadoras()
            this.atualizarPedidos()
        }
    }
}
</script>

<style>
</style>