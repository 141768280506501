<template>
    <v-app id="app">
        <HeaderApp />
        <MenuApp />
        <LoadingPage v-if="validatingToken" />
        <ContentApp v-else />
        <FooterApp />
    </v-app>
</template>

<script>
import HeaderApp from '@/components/templatesApp/HeaderApp'
import MenuApp   from '@/components/templatesApp/MenuApp'
import LoadingPage from '@/components/templatesApp/LoadingPage'
import ContentApp from '@/components/templatesApp/ContentApp'
import FooterApp from '@/components/templatesApp/FooterApp'
import consts from '@/global/consts'
import axios from 'axios'

export default {
    name: 'App',
    components: {
        HeaderApp,
        MenuApp,
        LoadingPage,
        ContentApp,
        FooterApp
    },
    data() {
        return {
            validatingToken: true
        }
    },
    methods: {
        async validateToken() {
            this.validatingToken = true
            
            const jsonUsr = localStorage.getItem(consts.userKeyUsuario)
            const usrData = JSON.parse(jsonUsr)
            
            this.$store.commit('setUsuario', null)

            if (usrData) {
                const resUsr = await axios.post(`${consts.urlBaseApi}/validar_token`, usrData)

                if (resUsr.data) {
                    this.$store.commit('setUsuario', usrData)
                }
                else {
                    localStorage.removeItem(consts.userKeyUsuario)
                }
            }
            else {
                this.validatingToken = false
                return
            }

            this.validatingToken = false
        }
    },
    created() {
        this.validateToken()
    }
}
</script>


<style>

</style>
