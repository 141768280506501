<template>
    <PageApp namePage="nova-empresa" title="Registre sua empresa" subtitle="Informe os dados de sua empresa e registre-se gratuitamente">
        <v-card>
            <v-card-text>
                <v-form>
                    <v-select v-model="empresa.emp_tipo" :items="fieldContents.listEmpTipo" label="Tipo" :color="consts.corEscura" dense />
                    <v-text-field v-model="empresa.emp_razao_social" label="Razão social" :color="consts.corEscura" dense />
                    <v-text-field v-model="empresa.emp_fantasia" label="Fantasia" :color="consts.corEscura" dense />
                    <v-text-field v-model="empresa.emp_cnpj" label="CNPJ" v-mask="'##.###.###/####-##'" :color="consts.corEscura" dense />
                    <v-text-field v-model="empresa.emp_email" label="E-mail" :color="consts.corEscura" dense />
                    <v-text-field v-model="empresa.emp_telefone" label="Telefone" v-mask="'(##)#####-####'" :color="consts.corEscura" dense />
                    <v-text-field v-model="empresa.emp_rua" label="Rua" :color="consts.corEscura" dense />
                    <v-text-field v-model="empresa.emp_numero" label="Número" :color="consts.corEscura" dense />
                    <v-text-field v-model="empresa.emp_bairro" label="Bairro" :color="consts.corEscura" dense />
                    <v-text-field v-model="empresa.emp_complemento" label="Complemento" :color="consts.corEscura" dense />
                    <v-text-field v-model="empresa.emp_cidade" label="Cidade" :color="consts.corEscura" dense />
                    <v-select v-model="empresa.emp_uf" :items="fieldContents.listUFs" label="UF" :color="consts.corEscura" dense />
                    <v-text-field v-model="empresa.usr_nome" label="Nome do responsável (usuário)" :color="consts.corEscura" dense />
                    <v-text-field v-model="empresa.usr_email" label="E-mail do responsável (usuário)" :color="consts.corEscura" dense />
                    <v-text-field v-model="empresa.usr_telefone" label="Telefone do responsável (usuário)" v-mask="'(##)#####-####'" :color="consts.corEscura" dense />
                    <v-text-field v-model="empresa.usr_senha" label="Senha" type="password" :color="consts.corEscura" dense />
                    <v-text-field v-model="empresa.usr_senha_conf" label="Confirmação de senha" type="password" :color="consts.corEscura" dense />
                </v-form>
            </v-card-text>
            <v-card-text>
                <p class="text-left" style="font-weight: 300">Ao preencher o formulário acima, você está de acordo com a nossa 
                    <a href class="privacidade" @click.prevent="functions.openSiteUrl(consts.siteCodezzPrivacidade)">
                        <strong>Política de Privacidade</strong>
                    </a>
                </p>
            </v-card-text>
            <v-card-actions>
                <v-btn class="ml-2 mb-2" @click="cadastrarEmpresa" :color="consts.corNeutraEscura" dark>Cadastrar</v-btn>
            </v-card-actions>
        </v-card>
    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import consts from '@/global/consts'
import functions from '@/global/functions'
import fieldContents from '@/global/fieldContents'
import axios from 'axios'

export default {
    name: 'NovaEmpresa',
    data() {
        return {
            fieldContents,
            consts,
            functions,
            empresa: {}
        }
    },
    components: {
        PageApp
    },
    methods: {
        cadastrarEmpresa() {
            const pBody = {
                emp_tipo: this.empresa.emp_tipo,
                emp_razao_social: this.empresa.emp_razao_social,
                emp_fantasia: this.empresa.emp_fantasia,
                emp_cnpj: functions.getOnlyNumbers(this.empresa.emp_cnpj),
                emp_email: this.empresa.emp_email,
                emp_telefone: functions.getOnlyNumbers(this.empresa.emp_telefone),
                emp_rua: this.empresa.emp_rua,
                emp_numero: this.empresa.emp_numero,
                emp_bairro: this.empresa.emp_bairro,
                emp_complemento: this.empresa.emp_complemento,
                emp_cidade: this.empresa.emp_cidade,
                emp_uf: this.empresa.emp_uf,
                usr_nome: this.empresa.usr_nome,
                usr_email: this.empresa.usr_email,
                usr_telefone: functions.getOnlyNumbers(this.empresa.usr_telefone),
                usr_senha: this.empresa.usr_senha,
                usr_senha_conf: this.empresa.usr_senha_conf,
            }

            axios.post(`${consts.urlBaseApi}/criar_empresa`, pBody)
                .then(() => {
                    functions.showSuccess()

                    this.$router.push({
                        name: 'homePage'
                    })
                })
                .catch(functions.showError)
        }
    }
}
</script>

<style>
    .privacidade {
        text-decoration: none;
    }
</style>