<template>
    <PageAppUser namePage="config-usuario" title="Configurações de usuário" subtitle="Configurações do usuário e da empresa" empAdmin="N">

        <v-expansion-panels v-model="panels" multiple>
            
            <v-alert dense type="info" class="ml-2">
                Caso deseja atualizar o plano atual da empresa, {{ consts.msgContatoCodezz }}
            </v-alert>

            <!-- Empresa -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Configurações da empresa</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-form>
                        <v-text-field v-model="config.emp_id" label="Código interno" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.emp_tipo_calc" label="Tipo" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.emp_razao_social" label="Razão social" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.emp_fantasia" label="Fantasia" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.emp_cnpj" label="CNPJ" v-mask="'##.###.###/####-##'" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.emp_email" label="E-mail" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.emp_telefone" label="Telefone" v-mask="'(##)#####-####'" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.emp_rua" label="Rua" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.emp_numero" label="Número" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.emp_bairro" label="Bairro" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.emp_complemento" label="Complemento" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.emp_cidade" label="Cidade" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.emp_uf" label="UF" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.emp_usuarios" label="Quantidade de usuários liberados" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.emp_transportadoras" label="Quantidade de transportadoras liberadas para sincronizar" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.emp_liberado_ate" label="Sistema liberado até" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.emp_criacao" label="Data de criação da empresa na plataforma" :color="consts.corEscura" dense readonly />
                    </v-form>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Usuário -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Configurações do usuário</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-form>
                        <v-text-field v-model="config.usr_id" label="Código interno" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.usr_nome" label="Nome" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.usr_email" label="E-mail" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.usr_telefone" label="Telefone" v-mask="'(##)#####-####'" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="config.usr_criacao" label="Data de criação do usuário na plataforma" :color="consts.corEscura" dense readonly />
                    </v-form>
                    
                </v-expansion-panel-content>
            </v-expansion-panel>

        </v-expansion-panels>
    </PageAppUser>
</template>

<script>
import PageAppUser from '@/components/templates/PageAppUser'
import consts from '@/global/consts'
import functions from '@/global/functions'
import axios from 'axios'

export default {
    name: 'ConfigUsuario',
    components: {
        PageAppUser
    },
    data() {
        return {
            consts,
            config: {},
            panels: []
        }
    },
    methods: {
        getConfiguracoes() {
            this.panels = []

            axios.get(`${consts.urlBaseApi}/configuracoes`)
                .then(r => {
                    this.config = r.data.data

                    this.config.emp_liberado_ate = functions.getDataFormatada(this.config.emp_liberado_ate)
                    this.config.emp_criacao = functions.getDataFormatada(this.config.emp_criacao)
                    this.config.usr_criacao = functions.getDataFormatada(this.config.usr_criacao)

                    if (this.config.emp_tipo == 'A') {
                        this.config.emp_tipo_calc = 'Administradora'
                    }
                    else if (this.config.emp_tipo == 'F') {
                        this.config.emp_tipo_calc = 'Fábrica'
                    }
                    else if (this.config.emp_tipo == 'L') {
                        this.config.emp_tipo_calc = 'Loja'
                    }
                    else {
                        this.config.emp_tipo_calc = 'Não definida'
                    }

                    this.panels = [0, 1]
                })
                .catch(functions.showError)
        }
    },
    mounted() {
        if (this.$store.state.usrInformado) {
            this.getConfiguracoes()
        }
    }
}
</script>

<style>

</style>