
// Exemplo de cores (item 2): https://en.99designs.com.br/blog/creative-inspiration/color-combinations/
const corClara        = 'rgb(215, 38,  49)'  //#d72631
const corEscura       = 'rgb(92,  60,  146)' //#5c3c92
const corNeutraClara  = 'rgb(162, 213, 198)' //#a2d5c6
const corNeutraEscura = 'rgb(7,   123, 138)' //#077b8a

const producaoCodezz = true

export default {
    corClara,
    corEscura,
    corNeutraClara,
    corNeutraEscura,
    corClaraCss: 'color: ' + corClara,
    corEscuraCss: 'color: ' + corEscura,
    corNeutraClaraCss: 'color: ' + corNeutraClara,
    corNeutraEscuraCss: 'color: ' + corNeutraEscura,

    urlBaseApi: producaoCodezz ? 'https://api-rastreamentos.codezz.com.br:3003' : 'http://localhost:3003',

    userKeyUsuario: '__rastreamentos_usuario_codezz',

    msgContatoCodezz: 'entre em contato conosco através do e-mail codezz@codezz.com.br',

    siteCodezz: 'https://www.codezz.com.br',
    siteCodezzPrivacidade: 'https://codezz.com.br/politica-privacidade'
}