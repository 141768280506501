<template>
    <PageAppUser namePage="diversos-xml" title="Importação de XML" subtitle="Lançamento de vários pedidos simultâneos através da importação de arquivos XML" empAdmin="S">
        
        <v-expansion-panels v-model="panels" multiple>
            
            <!-- Arquivos XML -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Seleção dos XML da NF-e</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-file-input v-model="arquivosXMLs" label="Selecione os arquivos" :color="consts.corEscura" variant="underlined" accept="text/xml" prepend-icon="mdi-xml" multiple />
                    <v-btn class="ml-8 my-2" @click="processarXMLs" :color="consts.corEscura" outlined>Importar</v-btn>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Retorno do processamento dos XMLs -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Dados da NF-e</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-col>
                        <v-row class="justify-end mb-3">
                            <v-progress-circular v-if="processando" class="ml-5" indeterminate :color="consts.corNeutraEscura" />
                            <v-btn v-else @click="processarTudo" :color="consts.corNeutraEscura" outlined>Processar tudo</v-btn>
                        </v-row>
                        <v-row>
                            <v-layout child-flex>
                                <v-data-table :headers="colunas" :items="xmlsProcessados" no-data-text="Nenhum XML processado">
                                    <template v-slot:[`item.btnActions`]="{ item }">
                                        <v-btn v-if="item.tipo == 'I' && !processando" class="mr-2" @click="processarRegistro(item)" :color="consts.corNeutraClara" x-small>Processar</v-btn>
                                    </template>
                                </v-data-table>
                            </v-layout>
                        </v-row>
                    </v-col>

                </v-expansion-panel-content>
            </v-expansion-panel>

        </v-expansion-panels>
    </PageAppUser>
</template>

<script>
import PageAppUser from '@/components/templates/PageAppUser'
import consts from '@/global/consts'
import functions from '@/global/functions'
import axios from 'axios'

export default {
    name: 'DiversosXMLs',
    components: {
        PageAppUser
    },
    data() {
        return {
            consts,
            processando: false,
            arquivosXMLs: null,
            xmlsProcessados: [],
            panels: [0],
            colunas: [
                {
                    text: 'Arquivo',
                    value: 'arquivo'
                },
                {
                    text: 'Retorno',
                    value: 'retorno'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        setConteudoArquivo(pArray, pIndex, pArquivoXMl) {
            try {
                //Leitura do XML (captura o conteúdo do arquivo)
                let xml = new FileReader()
                xml.readAsText(pArquivoXMl)
                xml.onloadend = () => {
                    pArray[pIndex].conteudo = xml.result
                }
            }
            catch (e) {
                functions.showError(e)
                
                pArray[pIndex].tipo = 'E' //Erro
                pArray[pIndex].conteudo = '',
                pArray[pIndex].retorno = 'ERRO: Não foi possível ler o arquivo importado.'
            }
        },
        async processarRegistro(item) {
            if (item.tipo == 'I') {
                //Lê o XML da NFe (faz a conversão para o que o backend espera receber)
                item.NFe = await functions.getConteudoNFeXML(item.conteudo, this.$store.state.usuario.emp_id)
                item.NFeBackend = {}

                //Aqui valida o XML importado pelo backend
                await axios.post(`${consts.urlBaseApi}/pedidos_xml`, item.NFe)
                    .then(r => {
                        item.NFeBackend = r.data.data
                    })
                    .catch(e => {
                        item.tipo = 'E'
                        item.retorno = `ERRO: ${e.response.data.erro}`
                    })

                //Se não deu erro, faz o envio do pedido/NFe pro backend. Aqui faz a inclusão dele
                if (item.tipo == 'I') {
                    
                    await axios.post(`${consts.urlBaseApi}/pedidos`, item.NFeBackend)
                        .then(r => {
                            item.tipo = 'S' //Sucesso !
                            item.retorno = `Pedido importado com sucesso [ ${r.data.ped_id} ].`
                        })
                        .catch(e => {
                            item.tipo = 'E'
                            
                            if (e.response.data.erro) {
                                item.retorno = `ERRO: ${e.response.data.erro}`
                            }
                            else {
                                item.retorno = 'ERRO: Não foi possível incluir o pedido'
                            }
                        })
                }
            }
        },
        async processarTudo() {
            this.processando = true

            //Não usa foreach por conta do await dentro. Dessa forma mostra visualmente pro usuário que está processando
            for (const el of this.xmlsProcessados) {
                await this.processarRegistro(el)
            }

            this.processando = false
        },
        processarXMLs() {
            this.xmlsProcessados = []

            if (this.arquivosXMLs) {
                if (this.arquivosXMLs.length > 0) {

                    if (this.arquivosXMLs.length > 150) { //Faz no máximo 150 por vez pra não "trancar" o processamento e o usuário ficar em dúvido do que foi importado ou não...
                        functions.showError('Não é permitido importar mais de 150 arquivos XML de uma única vez.')
                    }
                    else {
                        let i = -1
    
                        this.arquivosXMLs.forEach(el => {
                            
                            i++
    
                            this.xmlsProcessados.push({
                                arquivo: el.name,
                                conteudo: '',
                                tipo: 'I', //Importado
                                retorno: 'Arquivo encontrado, necessário processar.'
                            })
    
                            this.setConteudoArquivo(this.xmlsProcessados, i, el)
                        })
    
                        this.panels = [1]
                    }
                }
                else {
                    functions.showError('Nenhum arquivo selecionado.')
                }
            }
            else {
                functions.showError('Nenhum arquivo selecionado.')
            }
        }
    }
}
</script>

<style>

</style>