<template>
    <PageCad namePage="cad-usuarios" title="Cadastro de usuários" subtitle="Cadastro de usuários da empresa" empAdmin="N"
        :colunas="colunas" :listRegistros="listUsuarios" :loadRegistro="loadRegistro" :loadRegistros="loadRegistros"
        :mode="mode" :save="save" :remove="remove" :reset="reset"
        labelCadSecundario="" :fncCadSecundario="null">

        <template v-slot:formulario>
            <v-form>
                <v-text-field v-model="usuario.usr_nome" label="Nome" :color="consts.corEscura" dense />
                <v-text-field v-model="usuario.usr_email" label="E-mail" :color="consts.corEscura" dense />
                <v-text-field v-model="usuario.usr_telefone" label="Telefone" v-mask="'(##)#####-####'" :color="consts.corEscura" dense />
                <v-text-field v-model="usuario.usr_senha" label="Senha" type="password" :color="consts.corEscura" dense />
                <v-text-field v-model="usuario.usr_senha_conf" label="Confirmação de senha" type="password" :color="consts.corEscura" dense />
                <v-checkbox v-model="usuario.usr_ativo" label="Ativo?" true-value="S" false-value="N" :color="consts.corEscura" dense />
            </v-form>
        </template>

    </PageCad>
</template>

<script>
import PageCad from '@/components/templates/PageCad'
import consts from '@/global/consts'
import functions from '@/global/functions'
import axios from 'axios'

export default {
    name: 'CadUsuarios',
    components: {
        PageCad
    },
    data() {
        return {
            consts,
            usuario: {},
            listUsuarios: [],
            mode: 'save',
            colunas: [
                {
                    text: 'Nome',
                    value: 'usr_nome'
                },
                {
                    text: 'E-mail',
                    value: 'usr_email'
                },
                {
                    text: 'Telefone',
                    value: 'usr_telefone'
                },
                {
                    text: 'Ativo?',
                    value: 'usr_ativo'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        loadRegistro(registro, mode = 'save') {
            this.mode = mode
            this.usuario = { ...registro }
        },
        loadRegistros() {
            axios.get(`${consts.urlBaseApi}/usuarios`)
                .then(r => {
                    this.listUsuarios = r.data.data
                })
                .catch(functions.showError)
        },
        remove() {
            axios.delete(`${consts.urlBaseApi}/usuarios/${this.usuario.usr_id}`)
                .then(() => {
                    functions.showSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        },
        reset() {
            this.mode = 'save'
            this.usuario = {}
            
            this.loadRegistros()
        },
        save() {
            const method = this.usuario.usr_id ? 'put' : 'post'
            const id = this.usuario.usr_id ? `/${this.usuario.usr_id}` : ''

            const pBody = {
                emp_id: this.$store.state.usuario.emp_id,
                usr_nome: this.usuario.usr_nome,
                usr_email: this.usuario.usr_email,
                usr_telefone: functions.getOnlyNumbers(this.usuario.usr_telefone),
                usr_senha: this.usuario.usr_senha,
                usr_senha_conf: this.usuario.usr_senha_conf,
                usr_ativo: this.usuario.usr_ativo ? this.usuario.usr_ativo : 'N'
            }

            axios[method](`${consts.urlBaseApi}/usuarios${id}`, pBody)
                .then(() => {
                    functions.showSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        }
    }
}
</script>

<style>

</style>