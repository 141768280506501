import Vue from 'vue'
import axios from 'axios'
import consts from '@/global/consts'

const formatarData = data => {
    const [dia, mes, ano] = data.split('/');
    return `${ano}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`
}

const getArrayXML = (pXmlDoc, pTag) => {
    return pXmlDoc.getElementsByTagName(pTag)
}

const getConteudoAtributo = (pItem, pAtt) => {
    return pItem.getAttribute(pAtt)
}

const getConteudoTag = (pXml, pTag, pValue) => {
    if (pValue) {
        return pXml.getElementsByTagName(pTag)[0].outerText
    }
    else {
        return pXml.getElementsByTagName(pTag)[0]
    }
}

const getConteudoXML = (pXml) => {
    const parser = new DOMParser()
    return parser.parseFromString(pXml, 'text/html')
}

const getConteudoNFeXML = (pXml, pEmpId) => {
    
    let vResult = {}

    try {
        const xmlDoc = getConteudoXML(pXml)

        const nfCab = getConteudoTag(xmlDoc, 'ide', false)
        const nfEmit = getConteudoTag(xmlDoc, 'emit', false)
        const nfDest = getConteudoTag(xmlDoc, 'dest', false)
        const nfTransp = getConteudoTag(xmlDoc, 'transporta', false)
        const nfItens = getArrayXML(xmlDoc, 'det')
    
        vResult.emp_id = pEmpId
        vResult.nNF = getConteudoTag(nfCab, 'nNF', true)
        vResult.serie = getConteudoTag(nfCab, 'serie', true)
        vResult.dhEmi = getConteudoTag(nfCab, 'dhEmi', true)
        vResult.chNFe = getConteudoTag(xmlDoc, 'chNFe', true)
        vResult.vNF = getConteudoTag(xmlDoc, 'vNF', true)
        vResult.vFrete = getConteudoTag(xmlDoc, 'vFrete', true)
        vResult.cnpjFab = getConteudoTag(nfEmit, 'CNPJ', true)
        vResult.cnpjLoj = getConteudoTag(nfDest, 'CNPJ', true)
        vResult.cnpjTranp = getConteudoTag(nfTransp, 'CNPJ', true)
    
        let itens = []
    
        for (const item of nfItens) {
            itens.push({
                item: getConteudoAtributo(item, 'nItem'),
                desc: getConteudoTag(item, 'xProd', true),
                valor: getConteudoTag(item, 'vProd', true)
            })
        }
    
        vResult.itens = itens
    
        return vResult
    }
    catch (e) {
        return vResult
    }
}

const getTransportadoraNFeXML = (pXml) => {
    let vRet = {}
    const xmlDoc = getConteudoXML(pXml)
    const nfTransp = getConteudoTag(xmlDoc, 'transporta', false)

    vRet.cnpj = getConteudoTag(nfTransp, 'CNPJ', true)
    vRet.nome = getConteudoTag(nfTransp, 'xNome', true)

    return vRet
}

const getFabricaNFeXML = (pXml) => {
    let vRet = {}
    const xmlDoc = getConteudoXML(pXml)
    const nfEmit = getConteudoTag(xmlDoc, 'emit', false)
    const ender = getConteudoTag(nfEmit, 'enderEmit', false)

    vRet.cnpj = getConteudoTag(nfEmit, 'CNPJ', true)
    vRet.nome = getConteudoTag(nfEmit, 'xNome', true)
    vRet.logradouro = getConteudoTag(ender, 'xLgr', true)
    vRet.numero = getConteudoTag(ender, 'nro', true)
    vRet.bairro = getConteudoTag(ender, 'xBairro', true)
    vRet.municipio = getConteudoTag(ender, 'xMun', true)
    vRet.uf = getConteudoTag(ender, 'UF', true)

    return vRet
}

const getLojaNFeXML = (pXml) => {
    let vRet = {}
    const xmlDoc = getConteudoXML(pXml)
    const nfDest = getConteudoTag(xmlDoc, 'dest', false)
    const ender = getConteudoTag(nfDest, 'enderDest', false)

    vRet.cnpj = getConteudoTag(nfDest, 'CNPJ', true)
    vRet.nome = getConteudoTag(nfDest, 'xNome', true)
    vRet.logradouro = getConteudoTag(ender, 'xLgr', true)
    vRet.numero = getConteudoTag(ender, 'nro', true)
    vRet.bairro = getConteudoTag(ender, 'xBairro', true)
    vRet.municipio = getConteudoTag(ender, 'xMun', true)
    vRet.uf = getConteudoTag(ender, 'UF', true)

    return vRet
}

const getDataFormatada = (pData) => {
    //Espera receber uma string no padrão data que vem do SQL "YYYY-MM-DD"
    if (pData) {
        if (pData.length >= 10) {
            const ano = pData.substring(0, 4)
            const mes = pData.substring(5, 7)
            const dia = pData.substring(8, 10)
    
            return `${dia}/${mes}/${ano}`
        }
        else {
            return ''
        }
    }
    else {
        return ''
    }
}

const getListFabricas = async () => {
    const ret = await axios.get(`${consts.urlBaseApi}/fabricas_ativas`)
        .then(r => {
            return r.data.data
        })
        .catch(() => {
            return []
        })

    let retorno = []

    ret.forEach(el => {
        retorno.push({
            text: el.fab_nome,
            value: el.fab_id
        })
    })

    return retorno
}

const getListLojas = async () => {
    const ret = await axios.get(`${consts.urlBaseApi}/lojas_ativas`)
        .then(r => {
            return r.data.data
        })
        .catch(() => {
            return []
        })

    let retorno = []

    ret.forEach(el => {
        retorno.push({
            text: el.loj_nome,
            value: el.loj_id
        })
    })

    return retorno
}

const getListStatusTransporte = async () => {
    const ret = await axios.get(`${consts.urlBaseApi}/status_transporte_ativos`)
        .then(r => {
            return r.data.data
        })
        .catch(() => {
            return []
        })

    let retorno = []

    ret.forEach(el => {
        retorno.push({
            text: el.stt_descricao,
            value: el.stt_id
        })
    })

    return retorno
}

const getListTransportadoras = async () => {
    const ret = await axios.get(`${consts.urlBaseApi}/transportadoras_ativas`)
        .then(r => {
            return r.data.data
        })
        .catch(() => {
            return []
        })

    let retorno = []

    ret.forEach(el => {
        retorno.push({
            text: el.tra_nome,
            value: el.tra_id
        })
    })

    return retorno
}

const getListTransportadorasSinc = async () => {
    const ret = await axios.get(`${consts.urlBaseApi}/transportadoras_sincronizadas`)
        .then(r => {
            return r.data.data
        })
        .catch(() => {
            return []
        })

    let retorno = []

    ret.forEach(el => {
        retorno.push({
            text: el.tra_nome,
            value: el.tra_id
        })
    })

    return retorno
}

function getOnlyNumbers(p) {
    if (p) {
        const r = p.replace(/\D/g, '')
        return r
    }
    else {
        return p
    }
}

function getPrimeiroDiaMes(pData) {
    //Espera-se receber a data em string no padrão DD/MM/YYYY
    const mes = pData.substring(3, 5)
    const ano = pData.substring(6, 10)

    return `01/${mes}/${ano}`
}

function openSiteUrl(url) {
    //Abre o site em uma nova aba do navegador (_blank)
    window.open(url, '_blank').focus()
}

function showError(e) {
    let msgErro

    if (e && e.response && e.response.data) {
        if (e.response.data.erro) {
            if (e.response.data.erro.sqlMessage) {
                msgErro = e.response.data.erro.sqlMessage
            } else {
                msgErro = e.response.data.erro
            }
        } else if (e.response.data.sqlMessage) {
            msgErro = e.response.data.sqlMessage
        } else {
            msgErro = e.response.data
        }
    } else if (typeof e === 'string') {
        msgErro = e
    } else {
        msgErro = ''
    }

    if (msgErro == '') {
        Vue.toasted.global.msgError()
    } else {
        Vue.toasted.global.msgError({
            msg: msgErro
        })
    }
}

function showSuccess(msg) {
    Vue.toasted.global.msgSuccess({
        msg
    })
}

function validarData(pData) {
    //Espera receber uma data no padrão DD/MM/YYYY
    if (pData.length == 10) {
        try {
            const vDia = pData.substring(0, 2)
            const vMes = pData.substring(3, 5)
            const vAno = pData.substring(6, 10)
            
            const vBarra1 = pData.substring(2, 3)
            const vBarra2 = pData.substring(5, 6)

            if (vDia > 0 && vDia <= 31 &&
                vMes > 0 && vMes <= 12 &&
                vAno > 0 && vBarra1 == '/' && vBarra2 == '/')
            {
                return true
            }
            else {
                return false
            }
        }
        catch (e) {
            return false
        }
    }
    else {
        return false
    }
}

export default {
    //Funções de XML
    getArrayXML,
    getConteudoAtributo,
    getConteudoTag,
    getConteudoXML,
    getConteudoNFeXML,
    getTransportadoraNFeXML,
    getFabricaNFeXML,
    getLojaNFeXML,
    
    //Demais funções
    formatarData,
    getDataFormatada,
    getListFabricas,
    getListLojas,
    getListStatusTransporte,
    getListTransportadoras,
    getListTransportadorasSinc,
    getOnlyNumbers,
    getPrimeiroDiaMes,
    openSiteUrl,
    showError,
    showSuccess,
    validarData
}