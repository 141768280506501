<template>
    <PageAppUser :namePage="namePage" :title="title" :subtitle="subtitle" :empAdmin="empAdmin">
        
        <v-expansion-panels v-model="panels" multiple>
            
            <!-- Formulário/Cadastro -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Formulário</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <slot name="formulario"></slot>
                    <v-row class="mt-3">
                        <v-btn class="ml-2 mb-2" v-if="mode === 'save'" @click="save" :color="consts.corNeutraClara">Salvar</v-btn>
                        <v-btn class="ml-2 mb-2" v-if="mode === 'remove'" @click="remove" :color="consts.corNeutraEscura" dark>Excluir</v-btn>
                        <v-btn class="ml-2 mb-2" @click="reset" :color="consts.corClara" dark>Cancelar</v-btn>
                    </v-row>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Listagem/Consulta -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Listagem</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-data-table class="tabela-listagem" :headers="colunas" :items="listRegistros"
                     no-data-text="Nenhum registro cadastrado">

                        <template v-slot:[`item.btnActions`]="{ item }">

                            <v-btn class="mr-2" @click="loadRegistro(item)" :color="consts.corNeutraClara" x-small>Editar</v-btn>
                            <v-btn @click="loadRegistro(item, 'remove')" :color="consts.corClara" x-small dark>Remover</v-btn>

                            <v-btn v-if="labelCadSecundario != ''" class="ml-2" @click="fncCadSecundario(item)" :color="consts.corNeutraEscura" x-small dark>
                                {{ labelCadSecundario }}
                            </v-btn>

                        </template>
                    </v-data-table>
                    
                </v-expansion-panel-content>
            </v-expansion-panel>

        </v-expansion-panels>

    </PageAppUser>
</template>

<script>
import PageAppUser from '@/components/templates/PageAppUser'
import consts from '@/global/consts'

export default {
    name: 'CadFabricas',
    props: [
        'namePage',
        'title',
        'subtitle',
        'empAdmin',
        'mode',
        'colunas',
        'listRegistros',
        'loadRegistro',
        'loadRegistros',
        'save',
        'remove',
        'reset',
        'labelCadSecundario',
        'fncCadSecundario'
    ],
    components: {
        PageAppUser
    },
    data() {
        return {
            consts,
            panels: [0, 1]
        }
    },
    mounted() {
        if (this.$store.state.usrInformado) {
            this.loadRegistros()
        }
    }
}
</script>

<style>

</style>