<template>
    <PageAppUser namePage="rastreamento-manual" title="Rastreie uma nota fiscal" subtitle="Rastreie uma nota fiscal através das transportadoras sincronizadas com a plataforma" empAdmin="S">

        <v-expansion-panels v-model="panels" multiple>
            
            <!-- Parâmetros da consulta de rastreamento -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Parâmetros da consulta</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-form>
                        <v-select v-model="parametros.tra_id" :items="transportadoras" label="Transportadora" :color="consts.corEscura" dense />
                        <v-text-field v-model="parametros.par_cnpj" label="CNPJ" v-mask="'##.###.###/####-##'" :color="consts.corEscura" dense />
                        <v-text-field v-model="parametros.par_nfe" label="Nota fiscal" :color="consts.corEscura" dense />
                    </v-form>
                    
                    <v-btn class="my-2" @click="rastrearTransportadora" :color="consts.corNeutraEscura" dark>Rastrear</v-btn>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Resultado do rastreamento -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Retorno do rastreamento</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-data-table class="tabela-rastreamento" :headers="colunas" :items="retorno.data" no-data-text="Nenhum rastreamento encontrado" :items-per-page="-1" hide-default-footer />

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

    </PageAppUser>
</template>

<script>
import PageAppUser from '@/components/templates/PageAppUser'
import consts from '@/global/consts'
import functions from '@/global/functions'
import axios from 'axios'

export default {
    name: 'RastreamentoManual',
    components: {
        PageAppUser
    },
    data() {
        return {
            consts,
            parametros: {},
            retorno: {},
            panels: [0],
            transportadoras: [],
            colunas: [
                {
                    text: 'Campo transportadora',
                    value: 'campo_tra'
                },
                {
                    text: 'Data transportadora',
                    value: 'data_tra'
                },
                {
                    text: 'Status de transporte',
                    value: 'stt_descricao'
                },
                {
                    text: 'Status ativo?',
                    value: 'stt_ativo'
                },
                {
                    text: 'Data',
                    value: 'data'
                }
            ]
        }
    },
    methods: {
        rastrearTransportadora() {
            this.retorno = {}

            const pBody = {
                emp_id: this.$store.state.usuario.emp_id,
                tra_id: this.parametros.tra_id,
                par_cnpj: functions.getOnlyNumbers(this.parametros.par_cnpj),
                par_nfe: this.parametros.par_nfe
            }

            axios.put(`${consts.urlBaseApi}/rastreamento_manual`, pBody)
                .then(r => {
                    functions.showSuccess()
                    this.retorno = r.data
                    this.panels = [1]
                })
                .catch(e => {
                    functions.showError(e)
                    this.retorno = e
                    this.panels = [0]
                })
        }
    },
    async mounted() {
        if (this.$store.state.usrInformado) {
            this.transportadoras = await functions.getListTransportadorasSinc()
        }
    }
}
</script>

<style>
</style>