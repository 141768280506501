<template>
    <PageCad namePage="cad-fabricas" title="Cadastro de fábricas" subtitle="Cadastro de fábricas da empresa" empAdmin="S"
        :colunas="colunas" :listRegistros="listFabricas" :loadRegistro="loadRegistro" :loadRegistros="loadRegistros"
        :mode="mode" :save="save" :remove="remove" :reset="reset"
        labelCadSecundario="" :fncCadSecundario="null">
        
        <template v-slot:formulario>
            <v-form>
                <v-text-field v-model="fabrica.fab_nome" label="Nome" :color="consts.corEscura" dense />
                <v-text-field v-model="fabrica.fab_cnpj" label="CNPJ" v-mask="'##.###.###/####-##'" :color="consts.corEscura" dense />
                <v-text-field v-model="fabrica.fab_rua" label="Rua" :color="consts.corEscura" dense />
                <v-text-field v-model="fabrica.fab_numero" label="Número" :color="consts.corEscura" dense />
                <v-text-field v-model="fabrica.fab_bairro" label="Bairro" :color="consts.corEscura" dense />
                <v-text-field v-model="fabrica.fab_complemento" label="Complemento" :color="consts.corEscura" dense />
                <v-text-field v-model="fabrica.fab_cidade" label="Cidade" :color="consts.corEscura" dense />
                <v-select v-model="fabrica.fab_uf" label="UF" :items="fieldContents.listUFs" :color="consts.corEscura" dense />
                <v-checkbox v-model="fabrica.fab_ativa" label="Ativa?" true-value="S" false-value="N" :color="consts.corEscura" dense />
            </v-form>
        </template>

    </PageCad>
</template>

<script>
import PageCad from '@/components/templates/PageCad'
import consts from '@/global/consts'
import functions from '@/global/functions'
import fieldContents from '@/global/fieldContents'
import axios from 'axios'

export default {
    name: 'CadFabricas',
    components: {
        PageCad
    },
    data() {
        return {
            consts,
            fieldContents,
            fabrica: {},
            listFabricas: [],
            mode: 'save',
            colunas: [
                {
                    text: 'Código',
                    value: 'fab_id'
                },
                {
                    text: 'Nome',
                    value: 'fab_nome'
                },
                {
                    text: 'CNPJ',
                    value: 'fab_cnpj'
                },
                {
                    text: 'Rua',
                    value: 'fab_rua'
                },
                {
                    text: 'Número',
                    value: 'fab_numero'
                },
                {
                    text: 'Bairro',
                    value: 'fab_bairro'
                },
                {
                    text: 'Cidade',
                    value: 'fab_cidade'
                },
                {
                    text: 'UF',
                    value: 'fab_uf'
                },
                {
                    text: 'Ativa?',
                    value: 'fab_ativa'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        loadRegistro(registro, mode = 'save') {
            this.mode = mode
            this.fabrica = { ...registro }
        },
        loadRegistros() {
            axios.get(`${consts.urlBaseApi}/fabricas`)
                .then(r => {
                    this.listFabricas = r.data.data
                })
                .catch(functions.showError)
        },
        remove() {
            axios.delete(`${consts.urlBaseApi}/fabricas/${this.fabrica.fab_id}`)
                .then(() => {
                    functions.showSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        },
        reset() {
            this.mode = 'save'
            this.fabrica = {}
            
            this.loadRegistros()
        },
        save() {
            const method = this.fabrica.fab_id ? 'put' : 'post'
            const id = this.fabrica.fab_id ? `/${this.fabrica.fab_id}` : ''

            const pBody = {
                emp_id: this.$store.state.usuario.emp_id,
                fab_nome: this.fabrica.fab_nome,
                fab_cnpj: functions.getOnlyNumbers(this.fabrica.fab_cnpj),
                fab_rua: this.fabrica.fab_rua,
                fab_numero: this.fabrica.fab_numero,
                fab_bairro: this.fabrica.fab_bairro,
                fab_complemento: this.fabrica.fab_complemento,
                fab_cidade: this.fabrica.fab_cidade,
                fab_uf: this.fabrica.fab_uf,
                fab_ativa: this.fabrica.fab_ativa ? this.fabrica.fab_ativa : 'N'
            }

            axios[method](`${consts.urlBaseApi}/fabricas${id}`, pBody)
                .then(() => {
                    functions.showSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        }
    }
}
</script>

<style>

</style>