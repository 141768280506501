import Vue from 'vue'
import VueRouter from 'vue-router'

import HomePage from '@/components/pages/init/HomePage'
import NovaEmpresa from '@/components/pages/init/NovaEmpresa'
import NoPermissionPage from '@/components/pages/init/NoPermissionPage'

import HomeUsuario from '@/components/pages/usuario/HomeUsuario'
import ConfigUsuario from '@/components/pages/usuario/ConfigUsuario'

import CadTransportadoras from '@/components/pages/cadastros/CadTransportadoras'
import CadTransportadorasApi from '@/components/pages/cadastros/CadTransportadorasApi'
import CadFabricas from '@/components/pages/cadastros/CadFabricas'
import CadLojas from '@/components/pages/cadastros/CadLojas'
import CadStatusTransp from '@/components/pages/cadastros/CadStatusTransp'
import CadUsuarios from '@/components/pages/cadastros/CadUsuarios'
import CadXMLs from '@/components/pages/cadastros/CadXMLs'

import CadPedidos from '@/components/pages/pedidos/CadPedidos'
import XmlPedidos from '@/components/pages/pedidos/XmlPedidos'
import DiversosXMLs from '@/components/pages/pedidos/DiversosXMLs'
import ConsPedidos from '@/components/pages/pedidos/ConsPedidos'
import PedidosAbertos from '@/components/pages/pedidos/PedidosAbertos'

import RastreamentoManual from '@/components/pages/rastreamentos/RastreamentoManual'
import ErrosRastreamento from '@/components/pages/rastreamentos/ErrosRastreamento'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'homePage',
        component: HomePage
    },
    {
        path: '/nova_empresa',
        name: 'novaEmpresa',
        component: NovaEmpresa
    },
    {
        path: '/home',
        name: 'homeUsuario',
        component: HomeUsuario
    },
    {
        path: '/transportadoras',
        name: 'transportadoras',
        component: CadTransportadoras
    },
    {
        path: '/transportadoras_api',
        name: 'transportadorasApi',
        component: CadTransportadorasApi,
        props: true
    },
    {
        path: '/fabricas',
        name: 'fabricas',
        component: CadFabricas
    },
    {
        path: '/lojas',
        name: 'lojas',
        component: CadLojas
    },
    {
        path: '/cadastros_xml',
        name: 'cadastrosXML',
        component: CadXMLs
    },
    {
        path: '/cadastro_pedido',
        name: 'cadPedidos',
        component: CadPedidos,
        props: true
    },
    {
        path: '/xml_pedido',
        name: 'xmlPedidos',
        component: XmlPedidos
    },
    {
        path: '/diversos_xmls',
        name: 'diversosXMLs',
        component: DiversosXMLs
    },
    {
        path: '/pedidos',
        name: 'pedidos',
        component: PedidosAbertos
    },
    {
        path: '/cons_pedidos',
        name: 'consPedidos',
        component: ConsPedidos
    },
    {
        path: '/status_transporte',
        name: 'statusTransporte',
        component: CadStatusTransp
    },
    {
        path: '/config',
        name: 'config',
        component: ConfigUsuario
    },
    {
        path: '/no_permission',
        name: 'noPermission',
        component: NoPermissionPage
    },
    {
        path: '/usuarios',
        name: 'usuarios',
        component: CadUsuarios
    },
    {
        path: '/rastreamento_manual',
        name: 'rastreamentoManual',
        component: RastreamentoManual
    },
    {
        path: '/erros_rastreamento',
        name: 'errosRastreamento',
        component: ErrosRastreamento
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
