<template>
    <PageCad namePage="cad-status-transp" title="Cadastro de status de transporte" subtitle="Cadastro de status de transporte da empresa" empAdmin="S"
        :colunas="colunas" :listRegistros="listStatusTransp" :loadRegistro="loadRegistro" :loadRegistros="loadRegistros"
        :mode="mode" :save="save" :remove="remove" :reset="reset"
        labelCadSecundario="" :fncCadSecundario="null">
        
        <template v-slot:formulario>
            <v-form>
                <v-text-field v-model="status.stt_descricao" label="Descrição" :color="consts.corEscura" dense />
                <v-select v-model="status.stt_tipo" label="Tipo" :items="fieldContents.listTipoStatusTransp" :color="consts.corEscura" dense />
                <v-checkbox v-model="status.stt_ativo" label="Ativo?" true-value="S" false-value="N" :color="consts.corEscura" dense />
            </v-form>
        </template>

    </PageCad>
</template>

<script>
import PageCad from '@/components/templates/PageCad'
import consts from '@/global/consts'
import functions from '@/global/functions'
import fieldContents from '@/global/fieldContents'
import axios from 'axios'

export default {
    name: 'CadStatusTransp',
    components: {
        PageCad
    },
    data() {
        return {
            consts,
            fieldContents,
            status: {},
            listStatusTransp: [],
            mode: 'save',
            colunas: [
                {
                    text: 'Código',
                    value: 'stt_id'
                },
                {
                    text: 'Descrição',
                    value: 'stt_descricao'
                },
                {
                    text: 'Tipo',
                    value: 'stt_tipo'
                },
                {
                    text: 'Ativo?',
                    value: 'stt_ativo'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        loadRegistro(registro, mode = 'save') {
            this.mode = mode
            this.status = { ...registro }
        },
        loadRegistros() {
            axios.get(`${consts.urlBaseApi}/status_transporte`)
                .then(r => {
                    this.listStatusTransp = r.data.data
                })
                .catch(functions.showError)
        },
        remove() {
            axios.delete(`${consts.urlBaseApi}/status_transporte/${this.status.stt_id}`)
                .then(() => {
                    functions.showSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        },
        reset() {
            this.mode = 'save'
            this.status = {}
            
            this.loadRegistros()
        },
        save() {
            const method = this.status.stt_id ? 'put' : 'post'
            const id = this.status.stt_id ? `/${this.status.stt_id}` : ''

            const pBody = {
                emp_id: this.$store.state.usuario.emp_id,
                stt_descricao: this.status.stt_descricao,
                stt_tipo: this.status.stt_tipo,
                stt_ativo: this.status.stt_ativo ? this.status.stt_ativo : 'N'
            }

            axios[method](`${consts.urlBaseApi}/status_transporte${id}`, pBody)
                .then(() => {
                    functions.showSuccess()
                    this.reset()
                })
                .catch(functions.showError)
        }
    }
}
</script>

<style>

</style>