<template>
    <v-hover v-slot="{ hover }">
        <v-card class="card-menu ma-4 text-center" :elevation="hover ? 14 : 4" width="200px" min-width="200px">

            <h3 class="mt-3 mb-2" :style="consts.corEscuraCss">
                {{ title }}
            </h3>

            <v-card-text class="text-center" :style="consts.corNeutraEscuraCss">

                <v-row class="justify-center">
                    <h2>{{ getNroMostrar(nroAtivo) }}</h2>
                    <h5 v-if="txtTotal != ''">/ {{ getNroMostrar(nroTotal) }}</h5> 
                </v-row>

                <v-row class="justify-center">

                    <strong v-if="txtTotal != ''">{{ txtAtivo }} </strong>
                    <div v-else>{{ txtAtivo }}</div>

                    <div v-if="txtTotal != ''">/{{ txtTotal }}</div>
                </v-row>

                <v-row class="justify-center">
                    <v-btn class="my-2" @click="abrirRota(nomeRota)" :color="consts.corNeutraEscura" outlined x-small>{{ nomeBotao }}</v-btn>
                </v-row>
            </v-card-text>
        </v-card>
    </v-hover>
</template>

<script>
import consts from '@/global/consts'

export default {
    name: 'CardMenu',
    props: [
        'title',
        'nroAtivo',
        'nroTotal',
        'txtAtivo',
        'txtTotal',
        'nomeRota',
        'nomeBotao'
    ],
    data() {
        return {
            consts
        }
    },
    methods: {
        abrirRota(nomeRota) {
            this.$router.push({
                name: nomeRota
            })
        },
        getNroMostrar(pNro) {
            if (pNro) {
                if (pNro > 0) {
                    return pNro
                }
                else {
                    return 0
                }
            }
            else {
                return 0
            }
        }
    }
}
</script>

<style>

</style>