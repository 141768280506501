<template>
    <PageApp namePage="home-page" title="Controle de rastreamentos da Codezz" subtitle="Seja bem vindo!">

        <div class="text-center">
            <div>
                <v-card>
                    <v-card-title>
                        Login
                    </v-card-title>
                    <v-card-text>
                            <v-text-field v-model="login.email" label="E-mail" :color="consts.corEscura" dense />
                            <v-text-field v-model="login.senha" label="Senha" type="password" :color="consts.corEscura" dense />
                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="ml-2 mb-2" @click="loginUsuario" :color="consts.corNeutraEscura" dark>Login</v-btn>
                    </v-card-actions>
                </v-card>
            </div>

            <div class="mt-5">
                <v-btn class="ma-2" @click="openDialogSenha" :color="consts.corNeutraEscura" outlined>Alterar senha</v-btn>
                <v-btn class="ma-2" @click="novaEmpresa" :color="consts.corNeutraEscura" outlined>Cadastrar nova empresa</v-btn>
            </div>
        </div>

        <!-- Dialog para alteração de senha -->
        <v-dialog v-model="mostrarAlterarSenha" max-width="400px">
            <v-card>
                <v-card-title>Altere sua senha</v-card-title>
                <v-card-text>
                    <v-text-field v-model="novaSenha.email" label="E-mail" :color="consts.corNeutraEscura" dense />
                    <v-text-field v-model="novaSenha.telefone" label="Telefone" v-mask="'(##)#####-####'" :color="consts.corNeutraEscura" dense />
                    <v-text-field v-model="novaSenha.senha" label="Senha"  type="password" :color="consts.corNeutraEscura" dense />
                    <v-text-field v-model="novaSenha.senha_conf" label="Confirme sua senha" type="password" :color="consts.corNeutraEscura" dense />
                </v-card-text>
                <v-card-text class="text-left" style="font-weight: 300">
                    <p>
                        Caso você não lembre o seu e-mail e/ou telefone, {{ consts.msgContatoCodezz }}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2 ml-2" @click="alterarSenha" :color="consts.corNeutraEscura" dark outlined>Cadastrar</v-btn>
                    <v-btn class="mb-2 ml-2" @click="mostrarAlterarSenha = false" :color="consts.corNeutraEscura" dark outlined>Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import consts from '@/global/consts'
import functions from '@/global/functions'
import axios from 'axios'

export default {
    name: 'HomePage',
    components: {
        PageApp
    },
    data() {
        return {
            consts,
            mostrarAlterarSenha: false,
            login: {},
            novaSenha: {}
        }
    },
    methods: {
        alterarSenha() {
            const pBody = {
                email: this.novaSenha.email,
                telefone: functions.getOnlyNumbers(this.novaSenha.telefone),
                senha: this.novaSenha.senha,
                senha_conf: this.novaSenha.senha_conf
            }

            axios.put(`${consts.urlBaseApi}/alterar_senha`, pBody)
                .then(() => {
                    functions.showSuccess()

                    this.login = {}
                    this.login.email = pBody.email

                    this.mostrarAlterarSenha = false
                })
                .catch(functions.showError)
        },
        loginUsuario() {
            axios.put(`${consts.urlBaseApi}/login`, this.login)
                .then(r => {
                    this.$store.commit('setUsuario', r.data)
                    localStorage.setItem(consts.userKeyUsuario, JSON.stringify(r.data))

                    this.$router.push({
                        name: 'homeUsuario'
                    })
                })
                .catch(functions.showError)
        },
        novaEmpresa() {
            this.$router.push({
                name: 'novaEmpresa'
            })
        },
        openDialogSenha() {
            this.novaSenha = {}
            this.mostrarAlterarSenha = true
        },
        testeRastreamento() {
            this.$router.push({
                name: 'testeTransportadora'
            })
        }
    },
    mounted() {
        if (this.$store.state.usrInformado) {

            this.$router.push({
                name: 'homeUsuario'
            })
        }
    }
}
</script>

<style>
</style>