<template>
    <PageAppUser namePage="home-usuario" title="Controle de rastreamentos da Codezz" :subtitle="subtitulo" empAdmin="N">
        
        <v-col>

            <!-- Empresa bloqueada (2) -->
            <v-alert v-if="tipoBloqueio == 2" dense type="error">
                A empresa está bloqueada, {{ consts.msgContatoCodezz }}
            </v-alert>

            <!-- Empresa liberada, porém mostra mensagem de aviso (1) = Aviso é apresentado com 5 dias de antecedência -->
            <v-alert v-if="tipoBloqueio == 1" dense type="warning">
                Atenção, a empresa será bloqueada {{ this.diasBloqueio == 0 ? 'amanhã' : `em ${this.diasBloqueio} ${this.diasBloqueio == 1 ? 'dia' : 'dias'}` }}, {{ consts.msgContatoCodezz }}
            </v-alert>

            <v-row v-if="dashboard.emp_tipo == 'A'" class="justify-center">

                <!-- Transportadoras -->
                <CardMenu title="Transportadoras" txtAtivo="sincronizadas" txtTotal="ativas" :nroAtivo="dashboard.transportadoras_sincronizadas" :nroTotal="dashboard.transportadoras_ativas"
                    nomeRota="transportadoras" nomeBotao="Abrir cadastro" />

                <!-- Fábricas -->
                <CardMenu title="Fábricas" txtAtivo="ativas" txtTotal="totais" :nroAtivo="dashboard.fabricas_ativas" :nroTotal="dashboard.fabricas"
                    nomeRota="fabricas" nomeBotao="Abrir cadastro" />

                <!-- Lojas -->
                <CardMenu title="Lojas" txtAtivo="ativas" txtTotal="totais" :nroAtivo="dashboard.lojas_ativas" :nroTotal="dashboard.lojas"
                    nomeRota="lojas" nomeBotao="Abrir cadastro" />
            </v-row>

            <v-row class="justify-center">

                <!-- Pedidos abertos -->
                <CardMenu title="Pedidos em aberto" txtAtivo="não entregues" txtTotal="" :nroAtivo="dashboard.pedidos_abertos" :nroTotal="0"
                    nomeRota="pedidos" nomeBotao="Pedidos" />

                <!-- Pedidos -->
                <CardMenu title="Pedidos" txtAtivo="pedidos" txtTotal="" :nroAtivo="dashboard.pedidos" :nroTotal="0"
                    nomeRota="consPedidos" nomeBotao="Consultar pedidos" />

                <!-- Usuários -->
                <CardMenu title="Usuários" txtAtivo="ativos" txtTotal="liberados" :nroAtivo="dashboard.usuarios_ativos" :nroTotal="dashboard.emp_usuarios"
                    nomeRota="usuarios" nomeBotao="Abrir cadastro" />
            </v-row>
        </v-col>

    </PageAppUser>
</template>

<script>
import PageAppUser from '@/components/templates/PageAppUser'
import CardMenu from '@/components/templates/CardMenu'
import consts from '@/global/consts'
import functions from '@/global/functions'
import axios from 'axios'

export default {
    name: 'HomeUsuario',
    components: {
        PageAppUser,
        CardMenu
    },
    data() {
        return {
            consts,
            subtitulo: '',
            dashboard: {},
            tipoBloqueio: 2, //0=Liberado; 1=Aviso; 2=Bloqueado
            diasBloqueio: 0
        }
    },
    methods: {
        refreshDashboard() {
            this.dashboard = {}

            axios.get(`${consts.urlBaseApi}/dashboard`)
                .then(r => {
                    this.dashboard = r.data.data
                })
                .catch(functions.showError)
        }
    },
    mounted() {
        if (this.$store.state.usrInformado) {

            this.subtitulo = `Seja bem vindo, ${this.$store.state.usuario.usr_nome}!`

            //Mensagem de sistema bloqueado
            this.tipoBloqueio = 2 //Bloqueado
            this.diasBloqueio = 0

            const pBody = {
                usr_id: this.$store.state.usuario.usr_id,
                emp_id: this.$store.state.usuario.emp_id
            }

            axios.put(`${consts.urlBaseApi}/empresa_liberada`, pBody)
                .then(r => {
                    const p = r.data
                    if (p.aviso) {
                        this.tipoBloqueio = 1 //aviso
                        this.diasBloqueio = p.dias_aviso
                    }
                    else if (p.liberado) {
                        this.tipoBloqueio = 0 //liberado
                    }
                    else {
                        this.tipoBloqueio = 2 //bloqueado
                    }
                })
                .catch(functions.showError)

            this.refreshDashboard()
        }
    }
}
</script>

<style>

</style>