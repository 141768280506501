<template>
    <PageAppUser namePage="xml-pedidos" title="Importação de XML" subtitle="Lançamento de pedidos para a empresa a partir da importação de um arquivo XML" empAdmin="S">
        
        <v-expansion-panels v-model="panels" multiple>
            
            <!-- Arquivo XML -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Seleção do XML da NF-e</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-file-input v-model="arquivoXml" label="Selecione o arquivo" :color="consts.corEscura" variant="underlined" accept="text/xml" prepend-icon="mdi-xml" dense />
                    <v-btn class="ml-8 my-2" @click="lerConteudoArquivo" :color="consts.corEscura" outlined>Importar XML</v-btn>
                    <v-btn class="ml-2" @click="variosXMLs" :color="consts.corEscura" outlined>Importar diversos arquivos</v-btn>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Dados do arquivo XML -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Dados da NF-e</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-form>
                        <v-text-field v-model="pedido.ped_numero" label="Número" :color="consts.corEscura" dense />
                        <v-text-field v-model="pedido.ped_cod_rastreio" label="Código de rastreio" :color="consts.corEscura" dense />
                        <v-text-field v-model="pedido.ped_numero_nf" label="Número NF-e" type="number" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="pedido.ped_serie_nf" label="Série NF-e" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="pedido.ped_chave_nf" label="Chave NF-e" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="pedido.ped_emissao" label="Emissão" v-mask="'##/##/####'" :color="consts.corEscura" dense readonly />
                        <v-select v-model="pedido.tra_id" label="Transportadora" :items="listTra" :color="consts.corEscura" dense readonly />
                        <v-select v-model="pedido.fab_id" label="Fábrica" :items="listFab" :color="consts.corEscura" dense readonly />
                        <v-select v-model="pedido.loj_id" label="Loja" :items="listLoj" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="pedido.ped_valor" label="Valor" type="number" :color="consts.corEscura" dense readonly />
                        <v-text-field v-model="pedido.ped_valor_frete" label="Valor do frete" type="number" :color="consts.corEscura" dense readonly />
                        <!-- v-checkbox v-model="pedido.ped_manual" label="Manual?" true-value="S" false-value="N" :color="consts.corEscura" dense readonly />
                        <v-checkbox v-model="pedido.ped_entregue" label="Entregue?" true-value="S" false-value="N" :color="consts.corEscura" dense readonly / -->
                    </v-form>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Itens do arquivo XML -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Itens da NF-e</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-data-table class="tabela-itens-nfe" :headers="colunas" :items="pedido.itens" no-data-text="Nenhum item cadastrado" :items-per-page="-1" hide-default-footer dense />

                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Opção para incluir o pedido/NF-e-->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Ações</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-btn @click="criarPedido" :color="consts.corNeutraEscura" dark>Incluir</v-btn>
                    <v-btn class="ml-2" @click="limparDados" :color="consts.corNeutraClara">Limpar dados</v-btn>

                </v-expansion-panel-content>
            </v-expansion-panel>

        </v-expansion-panels>

        <v-dialog v-model="dlgPedido" max-width="500px">
            <v-card>
                <v-card-title></v-card-title> <!-- Só pra dar o espaçamento necessário do card -->
                <v-card-text>
                    Pedido {{ vPedId }} incluído com sucesso. O que deseja fazer?
                </v-card-text>
                <v-card-actions>
                    <v-btn class="mb-2" @click="abrirPedido" :color="consts.corNeutraEscura" outlined>Visualizar o pedido</v-btn>
                    <v-btn class="mb-2 ml-2" @click="dlgPedido = false" :color="consts.corNeutraEscura" outlined>Importar outro XML</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </PageAppUser>
</template>

<script>
import PageAppUser from '@/components/templates/PageAppUser'
import consts from '@/global/consts'
import functions from '@/global/functions'
import axios from 'axios'

export default {
    name: 'XmlPedidos',
    components: {
        PageAppUser
    },
    data() {
        return {
            consts,
            arquivoXml: null,
            conteudoXml: '',
            vPedId: 0,
            pedido: {},
            listFab: [],
            listLoj: [],
            listTra: [],
            panels: [0],
            dlgPedido: false,
            colunas: [
                {
                    text: 'Item',
                    value: 'itp_item'
                },
                {
                    text: 'Descrição',
                    value: 'itp_descricao'
                },
                {
                    text: 'Valor',
                    value: 'itp_valor'
                }
            ]
        }
    },
    methods: {
        abrirPedido() {
            this.$router.push({
                name: 'cadPedidos',
                params: {
                    pedId: this.vPedId
                }
            })
        },
        criarPedido() {
            axios.post(`${consts.urlBaseApi}/pedidos`, this.pedido)
                .then(r => {
                    functions.showSuccess('Pedido criado com sucesso.')
                    this.vPedId = r.data.ped_id

                    if (this.vPedId > 0) {
                        this.dlgPedido = true
                    }
                    else {
                        this.limparDados()
                    }
                })
                .catch(functions.showError)
        },
        lerConteudoArquivo() {
            if (this.arquivoXml) {

                this.conteudoXml = ''

                try {
                    //Leitura do XML (captura o conteúdo do arquivo)
                    let xml = new FileReader()
                    xml.readAsText(this.arquivoXml)
                    xml.onloadend = () => {
                        this.conteudoXml = xml.result
                    }
                }
                catch (e) {
                    functions.showError(e)
                }
            }
            else {
                functions.showError('Arquivo não informado ou inválido.')
            }
        },
        limparDados() {
            this.arquivoXml = null
            this.pedido = {}
            this.panels = [0]
        },
        importarXml(pXml) {

            this.limparDados()
            
            const pBody = functions.getConteudoNFeXML(pXml, this.$store.state.usuario.emp_id)

            axios.post(`${consts.urlBaseApi}/pedidos_xml`, pBody)
                .then(r => {
                    this.pedido = r.data.data

                    functions.showSuccess('NF-e importada com sucesso.')
                    this.panels = [1, 2, 3]
                })
                .catch(functions.showError)
        },
        variosXMLs() {
            this.$router.push({
                name: 'diversosXMLs'
            })
        }
    },
    async mounted() {
        if (this.$store.state.usrInformado) {
            this.listFab = await functions.getListFabricas()
            this.listLoj = await functions.getListLojas()
            this.listTra = await functions.getListTransportadoras()
        }
    },
    watch: {
        //Esse watch dispara a importação do XML. Isso não acontece diretamente pois tem o tempo do navegador carregar o conteúdo do arquivo importado
        conteudoXml() {
            if (this.conteudoXml != '') {
                this.importarXml(this.conteudoXml)
            }
        },
        dlgPedido() {
            if (!this.dlgPedido) {
                this.limparDados()
            }
        }
    }
}
</script>

<style>

</style>