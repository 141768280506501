<template>
    <PageApp namePage="no-permission-page" title="Usuário sem permissão" subtitle="Usuário não possui permissão para acessar a respectiva tela">
        <v-column class="text-center">
            <v-alert dense type="warning">
                Você não possui permissão para acessar a página
            </v-alert>
            <v-btn class="mt-5" @click="voltarInicio" :color="consts.corNeutraEscura" outlined>
                Voltar para o início
            </v-btn>
        </v-column>
    </PageApp>
</template>

<script>
import PageApp from '@/components/templates/PageApp'
import consts from '@/global/consts'

export default {
    name: 'NoPermissionPage',
    components: {
        PageApp
    },
    data() {
        return {
            consts
        }
    },
    methods: {
        voltarInicio() {
            this.$router.push({
                name: 'homeUsuario'
            })
        }
    }
}
</script>

<style>

</style>