<template>
    <v-container :id="namePage">

        <!-- Aqui nessa div está o título da págona -->
        <div>
            <span class="text-h5" :style="consts.corNeutraEscuraCss">
                <strong>{{ title }}</strong>
            </span>
            <p :style="consts.corNeutraEscuraCss">
                {{ subtitle }}
            </p>
            <hr>
            <br>
        </div>

        <!-- Aqui está o conteúdo do componente -->
        <v-layout align-center justify-center>
            <slot></slot>
        </v-layout>

    </v-container>
</template>

<script>
import consts from '@/global/consts'

export default {
    name: 'PageApp',
    props: [
        'namePage',
        'title',
        'subtitle'
    ],
    data() {
        return {
            consts
        }
    }
}
</script>

<style>

</style>