<template>
    <v-navigation-drawer id="menu-app" v-model="$store.state.menuSistema" v-if="$store.state.usrInformado" absolute temporary>
        <v-list nav dense expand>

            <v-list-group v-for="(menu, i) in menus" :key="i" :value="menu.show" :color="consts.corEscura">

                <template v-slot:activator>
                    <v-list-item-title :style="consts.corEscuraCss">
                        {{ menu.title }}
                    </v-list-item-title>
                </template>

                <v-list-item v-for="(subMn, j) in getItensMenu(menu.sub)" :key="j" :color="consts.corNeutraEscura"
                 @click="subMn.to ? encaminharRota(subMn.to) : subMn.fnc()">

                    <v-list-item-icon class="ml-5">
                        <v-icon :style="consts.corNeutraEscuraCss">
                            {{ subMn.icon }}
                        </v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>
                        <span :style="consts.corNeutraEscuraCss">
                            {{ subMn.title }}
                        </span>
                    </v-list-item-title>

                </v-list-item>
            </v-list-group>

        </v-list>
    </v-navigation-drawer>
</template>

<script>
import consts from '@/global/consts'

export default {
    name: 'MenuApp',
    data() {
        return {
            consts,
            menus: [
                {
                    title: 'Cadastros',
                    show: true,
                    sub: [
                        {
                            title: 'Transportadoras',
                            admin: true,
                            icon: 'mdi-truck', 
                            to: 'transportadoras',
                            fnc: null
                        },
                        {
                            title: 'Fábricas',
                            admin: true,
                            icon: 'mdi-factory', 
                            to: 'fabricas',
                            fnc: null
                        },
                        {
                            title: 'Lojas',
                            admin: true,
                            icon: 'mdi-store', 
                            to: 'lojas',
                            fnc: null
                        },
                        {
                            title: 'Importação de XML',
                            admin: true,
                            icon: 'mdi-xml', 
                            to: 'cadastrosXML',
                            fnc: null
                        },
                        {
                            title: 'Status de transporte',
                            admin: true,
                            icon: 'mdi-train-car',
                            to: 'statusTransporte',
                            fnc: null
                        },
                        {
                            title: 'Usuários',
                            admin: false,
                            icon: 'mdi-account-group',
                            to: 'usuarios',
                            fnc: null
                        }
                    ]
                },
                {
                    title: 'Pedidos',
                    show: true,
                    sub: [
                        {
                            title: 'Cadastro',
                            admin: true,
                            icon: 'mdi-basket-plus',
                            to: 'cadPedidos',
                            fnc: null
                        },
                        {
                            title: 'Importação de XML',
                            admin: true,
                            icon: 'mdi-xml',
                            to: 'xmlPedidos',
                            fnc: null
                        },
                        {
                            title: 'Em aberto',
                            admin: false,
                            icon: 'mdi-basket', 
                            to: 'pedidos',
                            fnc: null
                        },
                        {
                            title: 'Consulta',
                            admin: false,
                            icon: 'mdi-basket-check', 
                            to: 'consPedidos',
                            fnc: null
                        }
                    ]
                },
                {
                    title: 'Outros',
                    show: true,
                    sub: [
                        {
                            title: 'Rastreamento',
                            admin: true,
                            icon: 'mdi-truck-fast-outline',
                            to: 'rastreamentoManual',
                            fnc: null
                        },
                        {
                            title: 'Erros de rastreamento',
                            admin: true,
                            icon: 'mdi-truck-alert-outline',
                            to: 'errosRastreamento',
                            fnc: null
                        },
                        {
                            title: 'Configurações',
                            admin: false,
                            icon: 'mdi-cog',
                            to: 'config',
                            fnc: null
                        },
                        {
                            title: 'Sair',
                            admin: false,
                            icon: 'mdi-logout',
                            to: null,
                            fnc: this.logoutUsuario
                        }
                    ]
                },
            ]
        }
    },
    methods: {
        getItensMenu(pArray) {
            return pArray.filter(obj => {
                return !obj.admin || this.$store.state.usuario.emp_tipo == 'A'
            })
        },
        encaminharRota(routeName) {
            this.$router.push({
                name: routeName
            })
        },
        logoutUsuario() {
            localStorage.removeItem(consts.userKeyUsuario)
            this.$store.commit('setUsuario', null)

            this.$router.push( {
                name: 'homePage'
            })
        }
    }
}
</script>

<style>

</style>