<template>
    <v-footer id="footer-app" :color="consts.corEscura" app dark>
        <v-row class="d-flex my-1" justify="center">
            <span class="d-none d-sm-flex">
                Desenvolvido por&ensp;
            </span> 
            <a class="site-codezz" href style="color: white" @click.prevent="functions.openSiteUrl(consts.siteCodezz)">
                <strong>Codezz Soluções em Tecnologia</strong>
            </a>
        </v-row>
    </v-footer>
</template>

<script>
import consts from '@/global/consts'
import functions from '@/global/functions'

export default {
    name: 'FooterApp',
    data() {
        return {
            consts,
            functions
        }
    }
}
</script>

<style>
    .site-codezz {
        text-decoration: none;
    }
</style>