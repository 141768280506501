<template>
    <PageAppUser namePage="cons-pedidos" title="Consulta de pedidos" subtitle="Consulta de pedidos da empresa" empAdmin="N">
        
        <v-expansion-panels v-model="panels" multiple>
            
            <!-- Parâmetros da consulta -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Parâmetros</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-form>
                        <v-text-field v-model="consulta.ped_emissao_ini" label="Emissão inicial" v-mask="'##/##/####'" :color="consts.corEscura" dense />
                        <v-text-field v-model="consulta.ped_emissao_fim" label="Emissão final" v-mask="'##/##/####'" :color="consts.corEscura" dense />
                        <v-text-field v-model="consulta.ped_numero" label="Número do pedido" :color="consts.corEscura" dense />
                        <v-text-field v-model="consulta.ped_numero_nf" label="Número da NF-e do pedido" type="number" :color="consts.corEscura" dense />
                        <v-select v-model="consulta.tra_id" :items="listTra" label="Transportadora" :color="consts.corEscura" dense />
                        <v-select v-model="consulta.fab_id" :items="listFab" label="Fábrica" :color="consts.corEscura" dense />
                        <v-select v-model="consulta.loj_id" :items="listLoj" label="Loja" :color="consts.corEscura" dense />
                        <v-select v-model="consulta.ped_manual" :items="listManual" label="Pedido manual?" :color="consts.corEscura" dense />
                        <v-select v-model="consulta.ped_entregue" :items="listEntregue" label="Pedido entregue?" :color="consts.corEscura" dense />
                    </v-form>

                    <v-btn class="mt-2" @click="consultarPedidos" :color="consts.corNeutraClara">Consultar</v-btn>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Listagem da consulta -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Pedidos da consulta</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-col>
                        <v-row class="justify-end mb-3">
                            <v-btn @click="dlgImpressao = true" :color="consts.corNeutraEscura" outlined>Imprimir listagem</v-btn>
                        </v-row>

                        <v-row>
                            <!-- Layout usado pra deixar a tabela com o widht total da página, sem essa tag ficam bordas dos lados. -->
                            <v-layout child-flex>
                                <TabelaPedidos :pedidos="pedidos" />
                            </v-layout>
                        </v-row>
                    </v-col>
                    
                </v-expansion-panel-content>
            </v-expansion-panel>

        </v-expansion-panels>

        <!-- Dialog: Impressão do pedido -->
        <v-dialog v-model="dlgImpressao" max-width="500px">
            <v-card>
                <v-card-title></v-card-title> <!-- Só pra dar o espaçamento necessário do card -->
                <v-card-text>
                    Deseja fazer o download da listagem de pedidos?
                </v-card-text>
                <v-card-actions>
                    <v-btn class="mb-2" @click="imprimirPedidosPDF" :color="consts.corNeutraEscura" outlined>PDF</v-btn>
                    <v-btn class="mb-2" @click="imprimirPedidosXLS" :color="consts.corNeutraEscura" outlined>Excel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2 ml-2" @click="dlgImpressao = false" :color="consts.corClara" outlined>Cancelar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </PageAppUser>
</template>

<script>
import PageAppUser from '@/components/templates/PageAppUser'
import TabelaPedidos from '@/components/templates/TabelaPedidos'
import consts from '@/global/consts'
import functions from '@/global/functions'
import axios from 'axios'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import XLSX from 'xlsx'

export default {
    name: 'ConsPedidos',
    components: {
        PageAppUser,
        TabelaPedidos
    },
    data() {
        return {
            consts,
            dlgImpressao: false,
            panels: [0],
            consulta: {},
            pedidos: [],
            listTra: [],
            listFab: [],
            listLoj: [],
            listManual: [
                {
                    text: 'Todos',
                    value: 'T'
                },
                {
                    text: 'Somente manuais',
                    value: 'S'
                },
                {
                    text: 'Somente não manuais',
                    value: 'N'
                }
            ],
            listEntregue: [
                {
                    text: 'Todos',
                    value: 'T'
                },
                {
                    text: 'Somente entregues',
                    value: 'S'
                },
                {
                    text: 'Somente não entregues',
                    value: 'N'
                }
            ]
        }
    },
    methods: {
        consultarPedidos() {
            axios.put(`${consts.urlBaseApi}/pedidos`, this.consulta)
                .then(r => {
                    functions.showSuccess()
                    this.pedidos = r.data.data

                    this.panels = [1]
                })
                .catch(functions.showError)
        },
        s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);

            for (let i = 0; i < s.length; i++) {
                view[i] = s.charCodeAt(i) & 0xFF;
            }

            return buf;
        },
        imprimirPedidosPDF() {
            if (this.pedidos.length > 0) {
                const doc = new jsPDF()
    
                const cHead = [[
                    'NF',
                    'Transportadora',
                    'Fábrica',
                    'Loja',
                    'Valor'
                ]]
    
                let vBody = []
    
                this.pedidos.forEach(el => {
                    vBody.push([
                        el.ped_numero_nf,
                        el.tra_nome,
                        el.fab_nome,
                        el.loj_nome,
                        el.ped_valor
                    ])
                })
                
                autoTable(doc, {
                    head: cHead,
                    body: vBody
                })
    
                doc.save('rastreamentos.pdf')

                this.dlgImpressao = false
            }
            else {
                functions.showError('Nenhum pedido para ser impresso.')
            }
        },
        imprimirPedidosXLS() {
            if (this.pedidos.length > 0) {
                
                let data = []
                
                this.pedidos.forEach(el => {
                    data.push({
                        Código: el.ped_id,
                        Número: el.ped_numero,
                        'Nota fiscal': el.ped_numero_nf,
                        Transportadora: el.tra_nome,
                        Fábrica: el.fab_nome,
                        Loja: el.loj_nome,
                        Emissão: el.ped_emissao,
                        Valor: el.ped_valor,
                        'Última atualização': el.rtp_data,
                        'Último status': el.ultimo_status
                    })
                })

                //código abaixo copiado de um exemplo do CHATGPT com a pergunta:
                //"Código pra exportar um arquivo excel (xls) a partir de um array de objetos no javascript"
                //& "converta esse exemplo em uma aplicação VueJS"

                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.json_to_sheet(data);

                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

                const wbout = XLSX.write(wb, { bookType: 'xls', type: 'binary' });

                const blob = new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' });
                const filename = 'rastreamentos.xls';

                if (typeof navigator.msSaveBlob !== 'undefined') {
                    navigator.msSaveBlob(blob, filename);
                } else {
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                }

                this.dlgImpressao = false
            }
            else {
                functions.showError('Nenhum pedido para ser impresso.')
            }
        }
    },
    async mounted() {
        if (this.$store.state.usrInformado) {

            this.listTra = await functions.getListTransportadoras()
            this.listFab = await functions.getListFabricas()
            this.listLoj = await functions.getListLojas()
            
            this.consulta.ped_manual = 'T'
            this.consulta.ped_entregue = 'T'
        }
    }
}
</script>

<style>

</style>