<template>
    <PageAppUser namePage="erros-rastreamento" title="Erros de rastreamentos" subtitle="Consulta dos erros que ocorreram no momento do rastreamento automático dos pedidos da plataforma" empAdmin="S">
        
        <v-expansion-panels v-model="panels" multiple>
            
            <!-- Parâmetros da consulta -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Parâmetros</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-form>
                        <v-text-field v-model="consulta.err_data_ini" label="Data inicial" v-mask="'##/##/####'" :color="consts.corEscura" dense />
                        <v-text-field v-model="consulta.err_data_fim" label="Data final" v-mask="'##/##/####'" :color="consts.corEscura" dense />
                        <v-text-field v-model="consulta.ped_id" label="Pedido (id)" type="number" :color="consts.corEscura" dense />
                    </v-form>

                    <v-btn class="mt-2" @click="consultarErros" :color="consts.corNeutraClara">Consultar</v-btn>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Listagem da consulta -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Erros da consulta</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-data-table :headers="colunas" :items="erros" no-data-text="Nenhum registro encontrado">
                
                        <template v-slot:[`item.err_data`]="{ item }">
                            {{ functions.getDataFormatada(item.err_data) }}
                        </template>

                    </v-data-table>
                    
                </v-expansion-panel-content>
            </v-expansion-panel>

        </v-expansion-panels>

    </PageAppUser>
</template>

<script>
import PageAppUser from '@/components/templates/PageAppUser'
import consts from '@/global/consts'
import functions from '@/global/functions'
import axios from 'axios'

export default {
    name: 'ErrosRastreamento',
    components: {
        PageAppUser
    },
    data() {
        return {
            consts,
            functions,
            consulta: {},
            erros: [],
            panels: [0],
            colunas: [
                {
                    text: 'Pedido (id)',
                    value: 'ped_id'
                },
                {
                    text: 'Data',
                    value: 'err_data'
                },
                {
                    text: 'URL',
                    value: 'err_url'
                },
                {
                    text: 'Mensagem',
                    value: 'err_mensagem'
                }
            ]
        }
    },
    methods: {
        consultarErros() {
            this.erros = []

            const pBody = {
                emp_id: this.$store.state.usuario.emp_id,
                err_data_ini: this.consulta.err_data_ini,
                err_data_fim: this.consulta.err_data_fim,
                ped_id: this.consulta.ped_id
            }

            axios.put(`${consts.urlBaseApi}/erros_rastreamento`, pBody)
                .then(r => {
                    functions.showSuccess()
                    this.erros = r.data.data

                    this.panels = [1]
                })
                .catch(functions.showError)
        }
    }
}
</script>

<style>

</style>