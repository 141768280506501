<template>
    <v-app-bar id="header-app" app :color="consts.corEscura" dark>

        <v-app-bar-nav-icon @click="visualizarMenu" v-if="$store.state.usrInformado" :style="consts.corNeutraClaraCss" /> 

        <router-link style="text-decoration: none; color: inherit;" to="/">
            <v-toolbar-title style="font-weight: 500">
                Seja bem vindo!
            </v-toolbar-title>
        </router-link>

    </v-app-bar>
</template>

<script>
import consts from '@/global/consts'

export default {
    name: 'HeaderApp',
    data() {
        return {
            consts
        }
    },
    methods: {
        visualizarMenu() {
            window.scrollTo(0, 0);
            this.$store.state.menuSistema = true
        }
    }
}
</script>

<style>

</style>