<template>
    <v-data-table :headers="colunas" :items="pedidos" no-data-text="Nenhum pedido encontrado">
                
        <template v-slot:[`item.ped_emissao`]="{ item }">
            {{ functions.getDataFormatada(item.ped_emissao) }}
        </template>

        <template v-slot:[`item.rtp_data`]="{ item }">
            {{ functions.getDataFormatada(item.rtp_data) }}
        </template>

        <template v-slot:[`item.btnActions`]="{ item }">
            <v-btn @click="abrirPedido(item)" :color="consts.corNeutraClara" x-small>Abrir</v-btn>
        </template>

    </v-data-table>
</template>

<script>
import consts from '@/global/consts'
import functions from '@/global/functions'

export default {
    name: 'TabelaPedidos',
    props: [
        'pedidos'
    ],
    data() {
        return {
            consts,
            functions,
            colunas: [
                {
                    text: 'Código',
                    value: 'ped_id'
                },
                {
                    text: 'Número',
                    value: 'ped_numero'
                },
                {
                    text: 'Nota fiscal',
                    value: 'ped_numero_nf'
                },
                {
                    text: 'Transportadora',
                    value: 'tra_nome'
                },
                {
                    text: 'Fábrica',
                    value: 'fab_nome'
                },
                {
                    text: 'Loja',
                    value: 'loj_nome'
                },
                {
                    text: 'Emissão',
                    value: 'ped_emissao'
                },
                {
                    text: 'Valor',
                    value: 'ped_valor'
                },
                {
                    text: 'Última atualização',
                    value: 'rtp_data'
                },
                {
                    text: 'Último status',
                    value: 'ultimo_status'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        abrirPedido(item) {
            this.$router.push({
                name: 'cadPedidos',
                params: {
                    pedId: item.ped_id
                }
            })
        }
    }
}
</script>

<style>

</style>
