import Vue from 'vue'
import App from './App.vue'

import vuetify from '@/config/vuetify'
import router from '@/config/router'
import store from '@/config/store'

import '@/config/messages'

//Lazyload para carregar apenas o que está sendo mostrado na página (carregamento dinâmico)
import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad)

//Facilitador de máscara para os componentes Vue e Vuetify
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective)

Vue.config.productionTip = true

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
