<template>
    <PageAppUser namePage="cadastros-xml" title="Importação de XML" subtitle="Criação de transportadoras, fábricas e lojas através da importação de arquivos XML" empAdmin="S">
        
        <v-expansion-panels v-model="panels" multiple>
            
            <!-- Arquivos XML -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Seleção dos XML da NF-e</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-file-input v-model="arquivosXMLs" label="Selecione os arquivos" :color="consts.corEscura" variant="underlined" accept="text/xml" prepend-icon="mdi-xml" multiple />
                    <v-btn class="ml-8 my-2" @click="processarXMLs" :color="consts.corEscura" outlined>Importar</v-btn>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Retorno do processamento dos XMLs -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Transportadoras, fábricas e lojas importadas</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-col>
                        <v-row class="justify-end mb-3">
                            <v-progress-circular v-if="processando" class="ml-5" indeterminate :color="consts.corNeutraEscura" />
                            <v-btn v-else @click="processarTudo" :color="consts.corNeutraEscura" outlined>Processar tudo</v-btn>
                        </v-row>
                        <v-row>
                            <v-layout child-flex>
                                <v-data-table :headers="colunas" :items="listaProcessados" no-data-text="Nenhum XML processado">
                                    <template v-slot:[`item.btnActions`]="{ item }">
                                        <v-btn v-if="item.estado == 'A' && !processando" class="mr-2" @click="processarRegistro(item)" :color="consts.corNeutraClara" x-small>Processar</v-btn>
                                    </template>
                                </v-data-table>
                            </v-layout>
                        </v-row>
                    </v-col>

                </v-expansion-panel-content>
            </v-expansion-panel>

        </v-expansion-panels>
    </PageAppUser>
</template>

<script>
import PageAppUser from '@/components/templates/PageAppUser'
import consts from '@/global/consts'
import functions from '@/global/functions'
import axios from 'axios'

export default {
    name: 'CadXMLs',
    components: {
        PageAppUser
    },
    data() {
        return {
            consts,
            arquivosXMLs: null,
            processando: false,
            xmlsProcessados: [],
            listaProcessados: [],
            panels: [0],
            colunas: [
                {
                    text: 'Tipo',
                    value: 'tipo'
                },
                {
                    text: 'Nome',
                    value: 'nome'
                },
                {
                    text: 'CNPJ',
                    value: 'cnpj'
                },
                {
                    text: 'Estado',
                    value: 'descricao'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        async processarRegistro(item) {
            if (item.estado == 'A') {
                
                if (item.tipo == 'T') {
                    //Transportadora
                    const pBody = {
                        emp_id: this.$store.state.usuario.emp_id,
                        tra_nome: item.nome,
                        tra_cnpj: functions.getOnlyNumbers(item.cnpj),
                        tra_sincronizada: 'N',
                        tra_ativa: 'S'
                    }

                    await axios.post(`${consts.urlBaseApi}/transportadoras`, pBody)
                        .then(() => {
                            item.estado = 'S' //Sucesso
                            item.descricao = 'Transportadora criada com sucesso!'
                        })
                        .catch(e => {
                            item.estado = 'E' //Erro

                            if (e.response.data.erro) {
                                item.descricao = `ERRO: ${e.response.data.erro}`
                            }
                            else {
                                item.descricao = 'Erro ao incluir a transportadora.'
                            }
                        })
                }
                else if (item.tipo == 'F') {
                    //Fábrica
                    const pBody = {
                        emp_id: this.$store.state.usuario.emp_id,
                        fab_nome: item.nome,
                        fab_cnpj: functions.getOnlyNumbers(item.cnpj),
                        fab_rua: item.logradouro,
                        fab_numero: item.numero,
                        fab_bairro: item.bairro,
                        fab_cidade: item.municipio,
                        fab_uf: item.uf,
                        fab_ativa: 'S'
                    }

                    axios.post(`${consts.urlBaseApi}/fabricas`, pBody)
                        .then(() => {
                            item.estado = 'S'
                            item.descricao = 'Fábrica criada com sucesso!'
                        })
                        .catch(e => {
                            item.estado = 'E'

                            if (e.response.data.erro) {
                                item.descricao = `ERRO: ${e.response.data.erro}`
                            }
                            else {
                                item.descricao = 'Erro ao incluir a fábrica.'
                            }
                        })
                }
                else if (item.tipo == 'L') {
                    //Loja
                    const pBody = {
                        emp_id: this.$store.state.usuario.emp_id,
                        loj_nome: item.nome,
                        loj_cnpj: functions.getOnlyNumbers(item.cnpj),
                        loj_rua: item.logradouro,
                        loj_numero: item.numero,
                        loj_bairro: item.bairro,
                        loj_cidade: item.municipio,
                        loj_uf: item.uf,
                        loj_ativa: 'S'
                    }

                    axios.post(`${consts.urlBaseApi}/lojas`, pBody)
                        .then(() => {
                            item.estado = 'S'
                            item.descricao = 'Loja criada com sucesso!'
                        })
                        .catch(e => {
                            item.estado = 'E'

                            if (e.response.data.erro) {
                                item.descricao = `ERRO: ${e.response.data.erro}`
                            }
                            else {
                                item.descricao = 'Erro ao incluir a loja.'
                            }
                        })
                }
            }
        },
        async processarTudo() {
            this.processando = true

            //Não usa foreach por conta do await dentro. Dessa forma mostra visualmente pro usuário que está processando
            for (const el of this.listaProcessados) {
                await this.processarRegistro(el)
            }

            this.processando = false
        },
        processarXMLs() {
            this.xmlsProcessados = []
            this.listaProcessados = []

            if (this.arquivosXMLs) {
                if (this.arquivosXMLs.length > 0) {

                    let i = -1

                    this.arquivosXMLs.forEach(el => {
                        
                        i++

                        this.xmlsProcessados.push({
                            arquivo: el,
                            conteudo: '',
                            transportadora: null,
                            fabrica: null,
                            loja: null
                        })

                        this.setConteudoArquivo(this.xmlsProcessados, i, el)
                    })

                    this.panels = [1]
                }
                else {
                    functions.showError('Nenhum arquivo selecionado.')
                }
            }
            else {
                functions.showError('Nenhum arquivo selecionado.')
            }
        },
        setConteudoArquivo(pArray, pIndex, pArquivoXMl) {
            try {
                //Leitura do XML (captura o conteúdo do arquivo)
                let xml = new FileReader()
                xml.readAsText(pArquivoXMl)

                xml.onloadend = () => {
                    
                    pArray[pIndex].conteudo = xml.result
                    
                    pArray[pIndex].transportadora = functions.getTransportadoraNFeXML(xml.result)
                    pArray[pIndex].fabrica = functions.getFabricaNFeXML(xml.result)
                    pArray[pIndex].loja = functions.getLojaNFeXML(xml.result)

                    if ((pArray[pIndex].transportadora.cnpj != '') && (pArray[pIndex].transportadora.nome != '')) {

                        const idx = this.listaProcessados.findIndex(obj => obj.cnpj == pArray[pIndex].transportadora.cnpj && obj.tipo == 'T')

                        if (idx < 0) {
                            this.listaProcessados.push({
                                tipo: 'T',
                                cnpj: pArray[pIndex].transportadora.cnpj,
                                nome: pArray[pIndex].transportadora.nome,
                                estado: 'A', //Aguardando...
                                descricao: 'Aguardando processo...'
                            })
                        }
                    }

                    if ((pArray[pIndex].fabrica.cnpj != '') && (pArray[pIndex].fabrica.nome != '')) {

                        const idx = this.listaProcessados.findIndex(obj => obj.cnpj == pArray[pIndex].fabrica.cnpj && obj.tipo == 'F')

                        if (idx < 0) {
                            this.listaProcessados.push({
                                tipo: 'F',
                                cnpj: pArray[pIndex].fabrica.cnpj,
                                nome: pArray[pIndex].fabrica.nome,
                                logradouro: pArray[pIndex].fabrica.logradouro,
                                numero: pArray[pIndex].fabrica.numero,
                                bairro: pArray[pIndex].fabrica.bairro,
                                municipio: pArray[pIndex].fabrica.municipio,
                                uf: pArray[pIndex].fabrica.uf,
                                estado: 'A', //Aguardando...
                                descricao: 'Aguardando processo...'
                            })
                        }
                    }

                    if ((pArray[pIndex].loja.cnpj != '') && (pArray[pIndex].loja.nome != '')) {

                        const idx = this.listaProcessados.findIndex(obj => obj.cnpj == pArray[pIndex].loja.cnpj && obj.tipo == 'L')

                        if (idx < 0) {
                            this.listaProcessados.push({
                                tipo: 'L',
                                cnpj: pArray[pIndex].loja.cnpj,
                                nome: pArray[pIndex].loja.nome,
                                logradouro: pArray[pIndex].loja.logradouro,
                                numero: pArray[pIndex].loja.numero,
                                bairro: pArray[pIndex].loja.bairro,
                                municipio: pArray[pIndex].loja.municipio,
                                uf: pArray[pIndex].loja.uf,
                                estado: 'A', //Aguardando...
                                descricao: 'Aguardando processo...'
                            })
                        }
                    }
                }
            }
            catch (e) {
                functions.showError(`Erro ao ler o arquivo [ ${pArquivoXMl.name} ]`)
            }
        }
    }
}
</script>

<style>

</style>