<template>
    <PageAppUser namePage="cad-pedidos" title="Cadastro de pedidos" :subtitle="subTitlePed" empAdmin="N">
        
        <v-expansion-panels v-model="panels" multiple>
            
            <!-- Formulário/Informações do pedido -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Cabeçalho do pedido</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-form>
                        <v-text-field v-model="pedido.ped_numero" label="Número" :color="consts.corEscura" dense />
                        <v-text-field v-model="pedido.ped_numero_nf" label="Número NF-e" type="number" :color="consts.corEscura" dense />
                        <v-text-field v-model="pedido.ped_serie_nf" label="Série NF-e" :color="consts.corEscura" dense />
                        <v-text-field v-model="pedido.ped_chave_nf" label="Chave NF-e" :color="consts.corEscura" dense />
                        <v-text-field v-model="pedido.ped_emissao" label="Emissão" v-mask="'##/##/####'" :color="consts.corEscura" dense />
                        <v-text-field v-model="pedido.ped_cod_rastreio" label="Código de rastreio" :color="consts.corEscura" dense />
                        <v-select v-model="pedido.tra_id" label="Transportadora" :items="listTra" :color="consts.corEscura" dense />
                        <v-select v-model="pedido.fab_id" label="Fábrica" :items="listFab" :color="consts.corEscura" dense />
                        <v-select v-model="pedido.loj_id" label="Loja" :items="listLoj" :color="consts.corEscura" dense />
                        <v-text-field v-model="pedido.ped_valor" label="Valor" type="number" :color="consts.corEscura" dense />
                        <v-text-field v-model="pedido.ped_valor_frete" label="Valor do frete" type="number" :color="consts.corEscura" dense />
                        <v-checkbox v-model="pedido.ped_manual" label="Manual?" true-value="S" false-value="N" :color="consts.corEscura" dense />
                        <v-checkbox v-model="pedido.ped_entregue" label="Entregue?" true-value="S" false-value="N" :color="consts.corEscura" dense />
                    </v-form>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Itens do pedido -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Itens do pedido</strong>
                    <v-spacer></v-spacer>
                    <v-btn v-if="this.$store.state.usuario.emp_tipo == 'A'" @click="showNewItem" @click.prevent.stop icon max-width="40" x-small>
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-data-table class="tabela-itens-ped" :headers="colItens" :items="itensPedido" no-data-text="Nenhum registro cadastrado"
                        :items-per-page="-1" hide-default-footer>

                        <template v-if="this.$store.state.usuario.emp_tipo == 'A'" v-slot:[`item.btnActions`]="{ item }">
                            <v-btn class="mr-2" @click="editarItem(item)" :color="consts.corNeutraClara" x-small>Editar</v-btn>
                            <v-btn @click="removerItem(item)" :color="consts.corClara" x-small dark>Remover</v-btn>
                        </template>
                    </v-data-table>

                </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Rastreamento dos pedidos -->
            <v-expansion-panel>
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Rastreamento do pedido</strong>
                    <v-spacer></v-spacer>
                    <v-btn v-if="pedInterno > 0 && this.$store.state.usuario.emp_tipo == 'A'" @click="openErrosRastreamento" @click.prevent.stop icon max-width="40" x-small>
                        <v-icon>mdi-alert-outline</v-icon>
                    </v-btn>
                    <v-btn v-if="pedInterno > 0 && this.$store.state.usuario.emp_tipo == 'A'" @click="showNewRastreamento" @click.prevent.stop icon max-width="40" x-small>
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-data-table class="tabela-rastreamento-ped" :headers="colRastreamento" :items="rastreamentosPedido" no-data-text="Nenhum registro cadastrado"
                        :items-per-page="-1" hide-default-footer />

                </v-expansion-panel-content>
            </v-expansion-panel>
            
            <!-- Ações -->
            <v-expansion-panel v-if="this.$store.state.usuario.emp_tipo == 'A'">
                <v-expansion-panel-header :style="consts.corEscuraCss">
                    <strong>Ações</strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <v-btn @click="salvar" :color="consts.corNeutraEscura" dark>
                        {{ pedInterno > 0 ? 'Salvar' : 'Incluir' }}
                    </v-btn>

                    <v-btn v-if="pedInterno > 0" class="ml-2" @click="dlgExclusao = true" :color="consts.corClara" dark>Remover</v-btn>
                    <v-btn v-if="pedInterno > 0" class="ml-2" @click="refresh" :color="consts.corNeutraClara">Refresh</v-btn>
                    <v-btn v-if="pedInterno > 0" class="ml-2" @click="atualizarRastreamento" :color="consts.corEscura" outlined>Rastrear</v-btn>
                    <v-btn v-if="pedInterno > 0" class="ml-2" @click="inicializar" :color="consts.corNeutraClara" outlined>Nova inclusão</v-btn>

                </v-expansion-panel-content>
            </v-expansion-panel>

        </v-expansion-panels>

        <!-- Dialog: Item do pedido -->
        <v-dialog v-model="dlgItem" max-width="500px">
            <v-card>
                <v-card-title>Item do pedido</v-card-title>
                <v-card-text>
                    <v-text-field v-model="pedItem.itp_item" label="Item" type="number" :color="consts.corEscura" dense />
                    <v-text-field v-model="pedItem.itp_descricao" label="Descrição" :color="consts.corEscura" dense />
                    <v-text-field v-model="pedItem.itp_valor" label="Valor" type="number" :color="consts.corEscura" dense />
                </v-card-text>
                <v-card-actions>
                    <v-btn class="mb-2 ml-2" @click="saveItem" :color="consts.corNeutraEscura" dark>Salvar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2 mr-2" @click="dlgItem = false" :color="consts.corNeutraEscura" dark outlined>Cancelar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog: Rastreamento do pedido -->
        <v-dialog v-model="dlgRastreamento" max-width="500px">
            <v-card>
                <v-card-title>Rastreamento</v-card-title>
                <v-card-text>
                    <v-select v-model="pedRastreamento.stt_id" label="Status" :items="listStt" :color="consts.corEscura" dense />
                    <v-text-field v-model="pedRastreamento.rtp_data" label="Data" v-mask="'##/##/####'" :color="consts.corEscura" dense />
                </v-card-text>
                <v-card-actions>
                    <v-btn class="mb-2 ml-2" @click="newRastreamento" :color="consts.corNeutraEscura" dark>Incluir</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2 mr-2" @click="dlgRastreamento = false" :color="consts.corNeutraEscura" dark outlined>Cancelar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialog: Exclusão do pedido -->
        <v-dialog v-model="dlgExclusao" max-width="500px">
            <v-card>
                <v-card-title></v-card-title> <!-- Só pra dar o espaçamento necessário do card -->
                <v-card-text>
                    Tem certeza que deseja excluir o pedido <strong>{{ this.pedInterno }}</strong>?
                </v-card-text>
                <v-card-actions>
                    <v-btn class="mb-2" @click="remove" :color="consts.corNeutraEscura" outlined>Excluir</v-btn>
                    <v-btn class="mb-2 ml-2" @click="dlgExclusao = false" :color="consts.corNeutraEscura" outlined>Cancelar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </PageAppUser>
</template>

<script>
import PageAppUser from '@/components/templates/PageAppUser'
import consts from '@/global/consts'
import functions from '@/global/functions'
import axios from 'axios'

export default {
    name: 'CadPedidos',
    props: [
        'pedId'
    ],
    components: {
        PageAppUser
    },
    data() {
        return {
            consts,
            panels: [0],
            pedInterno: -1,
            pedido: {},
            itensPedido: [],
            pedItem: {},
            countItem: 0,
            rastreamentosPedido: [],
            pedRastreamento: {},
            listFab: [],
            listLoj: [],
            listStt: [],
            listTra: [],
            dlgItem: false,
            dlgRastreamento: false,
            dlgExclusao: false,
            subTitlePed: '',
            colItens: [
                {
                    text: 'Código',
                    value: 'itp_id'
                },
                {
                    text: 'Item',
                    value: 'itp_item'
                },
                {
                    text: 'Descrição',
                    value: 'itp_descricao'
                },
                {
                    text: 'Valor',
                    value: 'itp_valor'
                },
                {
                    text: 'Ações',
                    value: 'btnActions',
                    sortable: false
                }
            ],
            colRastreamento: [
                {
                    text: 'Código',
                    value: 'rtp_id'
                },
                {
                    text: 'Status',
                    value: 'stt_id'
                },
                {
                    text: 'Descrição do status',
                    value: 'stt_descricao'
                },
                {
                    text: 'Data',
                    value: 'rtp_data'
                },
                {
                    text: 'Atualização',
                    value: 'rtp_atualizacao'
                }
            ]
        }
    },
    methods: {
        atualizarRastreamento() {
            axios.put(`${consts.urlBaseApi}/rastreamento_pedido`, { ped_id: this.pedInterno })
                .then(r => {
                    functions.showSuccess(r.data.msg)
                    this.refresh()
                })
                .catch(functions.showError)
        },
        editarItem(pItem) {
            this.pedItem = { ...pItem }
            this.dlgItem = true
        },
        inicializar() {
            this.pedInterno = -1
            this.refresh()
        },
        saveItem() {
            if (!this.pedItem.itp_item || this.pedItem.itp_item <= 0) {
                functions.showError('O item deve ser informado.')
            }
            else if (!this.pedItem.itp_descricao || this.pedItem.itp_descricao == '') {
                functions.showError('A descrição deve ser informada.')
            }
            else if (!this.pedItem.itp_valor || this.pedItem.itp_valor <= 0) {
                functions.showError('O valor deve ser informado.')
            }
            else {
                const vIndex = this.itensPedido.findIndex(obj => {
                    return obj.itp_id == this.pedItem.itp_id
                })

                if (vIndex >= 0) {
                    this.itensPedido[vIndex].itp_item = this.pedItem.itp_item
                    this.itensPedido[vIndex].itp_descricao = this.pedItem.itp_descricao
                    this.itensPedido[vIndex].itp_valor = this.pedItem.itp_valor
                }
                else {
                    this.countItem++

                    this.itensPedido.push({
                        itp_id: this.countItem * -1,
                        ped_id: null,
                        itp_item: this.pedItem.itp_item,
                        itp_descricao: this.pedItem.itp_descricao,
                        itp_valor: this.pedItem.itp_valor
                    })
                }

                this.dlgItem = false
            }
        },
        newRastreamento() {
            if (!this.pedRastreamento.stt_id || this.pedRastreamento.stt_id <= 0) {
                functions.showError('O status deve ser informado.')
            }
            else if (!this.pedRastreamento.rtp_data || this.pedRastreamento.rtp_data == '') {
                functions.showError('A data deve ser informada.')
            }
            else if (this.pedRastreamento.rtp_data.length != 10) {
                functions.showError('A data está inválida.')
            }
            else {
                const pBody = {
                    ped_id: this.pedInterno,
                    stt_id: this.pedRastreamento.stt_id,
                    rtp_data: this.pedRastreamento.rtp_data
                }

                axios.post(`${consts.urlBaseApi}/rastreamentos_pedido`, pBody)
                    .then(() => {
                        functions.showSuccess()
                        this.refresh()

                        this.dlgRastreamento = false
                    })
                    .catch(functions.showError)
            }
        },
        openErrosRastreamento() {
            this.$router.push({
                name: 'errosRastreamento'
            })
        },
        refresh() {
            this.pedido = {}
            this.itensPedido = []
            this.rastreamentosPedido = []

            this.countItem = 0

            //Método pode ser chamado apenas para inicializar as variáveis, por isso o if abaixo
            if (this.pedInterno > 0) {

                this.subTitlePed = `Pedido ${this.pedInterno}`

                axios.get(`${consts.urlBaseApi}/pedidos/${this.pedInterno}`)
                    .then(r => {
                        this.pedido = r.data.data
                        this.itensPedido = r.data.data.itens
                        this.rastreamentosPedido = r.data.data.rastreamentos

                        this.pedido.ped_emissao = functions.getDataFormatada(this.pedido.ped_emissao)

                        if (this.rastreamentosPedido.length > 0) {
                            this.rastreamentosPedido.forEach(el => {
                                el.rtp_data = functions.getDataFormatada(el.rtp_data)
                                el.rtp_atualizacao = functions.getDataFormatada(el.rtp_atualizacao)
                            })
                        }

                        delete this.pedido.itens
                        delete this.pedido.rastreamentos
                    })
                    .catch(functions.showError)
            }
            else {
                this.subTitlePed = 'Lançamento de pedidos para a empresa'
            }
        },
        remove() {
            axios.delete(`${consts.urlBaseApi}/pedidos/${this.pedInterno}`)
                .then(() => {
                    functions.showSuccess()
                    this.inicializar()

                    this.dlgExclusao = false
                })
                .catch(functions.showError)
        },
        removerItem(pItem) {
            const pNewItens = this.itensPedido.filter(obj => {
                return obj.itp_id != pItem.itp_id
            })

            this.itensPedido = pNewItens
        },
        salvar() {
            const pBody = {
                ped_id: this.pedInterno,
                emp_id: this.$store.state.usuario.emp_id,
                ped_numero: this.pedido.ped_numero,
                ped_numero_nf: this.pedido.ped_numero_nf,
                ped_serie_nf: this.pedido.ped_serie_nf,
                ped_chave_nf: this.pedido.ped_chave_nf,
                ped_emissao: this.pedido.ped_emissao,
                ped_cod_rastreio: this.pedido.ped_cod_rastreio,
                tra_id: this.pedido.tra_id,
                fab_id: this.pedido.fab_id,
                loj_id: this.pedido.loj_id,
                ped_valor: this.pedido.ped_valor,
                ped_valor_frete: this.pedido.ped_valor_frete,
                ped_manual: this.pedido.ped_manual ? this.pedido.ped_manual : 'N',
                ped_entregue: this.pedido.ped_entregue ? this.pedido.ped_entregue : 'N'
            }

            pBody.itens = this.itensPedido

            pBody.itens.forEach(el => {
                el.ped_id = this.pedInterno
            })

            axios.post(`${consts.urlBaseApi}/pedidos`, pBody)
                .then(r => {
                    //Inicializa com o pedido interno, pois se for update não vai retornar o id do pedido (pois ele já existe !!)
                    if (r) {
                        if (this.pedInterno <= 0 && r.data.ped_id > 0) {
                            this.pedInterno = r.data.ped_id
                        }
                    }

                    functions.showSuccess()
                    this.refresh()
                })
                .catch(functions.showError)
        },
        showNewItem() {
            this.pedItem = {}
            this.dlgItem = true
        },
        showNewRastreamento() {
            this.pedRastreamento = {}
            this.dlgRastreamento = true
        }
    },
    async mounted() {
        if (this.$store.state.usrInformado) {

            this.listFab = await functions.getListFabricas()
            this.listLoj = await functions.getListLojas()
            this.listStt = await functions.getListStatusTransporte()
            this.listTra = await functions.getListTransportadoras()

            if (this.pedId > 0) {
                this.subTitlePed = `Pedido ${this.pedId}`
                this.pedInterno = this.pedId
            }
            else {
                this.subTitlePed = 'Lançamento de pedidos para a empresa'
                this.pedInterno = -1
            }

            this.refresh()
        }
        //Não precisa do else pois o componente PageAppUser já possui o tratamento de voltar pra homePage caso não tiver usuário informado
    }
}
</script>

<style>

</style>